import handleErrorResponse from "./HandleErrors";

export default function handlePromiseError(
  promiseFunc,
  handleErrorFunc = null
) {
  return promiseFunc.catch((error) => {
    if (typeof handleErrorFunc === "function") {
      handleErrorFunc(error);
    } else {
      handleErrorResponse(error);
    }

    console.log("====================================");
    console.log("handlePromiseError", error);
    console.log("====================================");

    return error;
  });
}
