/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#0c293a",
          bottom: 0,
          color: "white",
        }}
      >
        <div className="container-fluid m-0 p-1">
          <div className="row pt-3">
            <NavLinks />
            <SocialLinks />
            <ContactLinks />
          </div>
        </div>
        <div className="container-fluid m-0 p-1 text-center">
          Powered by{" "}
          <a target="_blank" href="https://azuatech.co.ke">
            AzuaTech
          </a>
          . Read our <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    );
  }
}

const SocialLinks = () => {
  return (
    <div className="col-md-4 ">
      <h5>Social Links</h5>
      <ul className="nav flex-column" style={{ height: "80%" }}>
        <li className="nav-item">
          <a
            className="nav-link active"
            target="_blank"
            href="https://facebook.com/billsmonk"
          >
            <i className="fab fa-facebook"></i> Facebook
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            target="_blank"
            href="https://www.linkedin.com/company/bills-monk"
          >
            <i className="fab fa-linkedin"></i> LinkedIn
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            target="_blank"
            href="https://instagram.com/azuatech"
          >
            <i className="fab fa-instagram-square"></i> Instagram
          </a>
        </li>
      </ul>
      <hr />
    </div>
  );
};
const ContactLinks = () => {
  return (
    <div className="col-md-4 ">
      <h5>Contact Us</h5>
      <ul className="nav flex-column" style={{ height: "80%" }}>
        <li className="nav-item">
          <a className="nav-link active" href="mailto:info@billsmonk.com">
            <i className="fas fa-envelope"></i> Email: info@billsmonk.com
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="tel:+254797224768">
            <i className="fas fa-phone-square"></i> Telephone: +254797224768
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="https://wa.me/254797224768?text=Hello%2C%0AI%27m%20am%20contacting%20you%20from%20your%20website%28bills%29"
          >
            <i className="fab fa-whatsapp-square"></i> WhatsApp: +254797224768
          </a>
        </li>
      </ul>
      <hr />
    </div>
  );
};

const NavLinks = () => {
  return (
    <div className="col-md-4 ">
      <h5>Quick Links</h5>
      <ul className="nav flex-column" style={{ height: "80%" }}>
        <NavItem href="/" display="Home" />
        <NavItem href="/about" display="About" />
        <NavItem href="/bills" display="Bills" />
        <NavItem href="/debts" display="Debts" />
        <NavItem href="/categories" display="Categories" />
      </ul>
      <hr />
    </div>
  );
};

const NavItem = ({ href, display }) => {
  return (
    <li className="nav-item">
      <a className="nav-link" href={href}>
        {display}
      </a>
    </li>
  );
};
