import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AppTitle } from "../components/common/Constants";
import pushImg from "../images/push_notification.jpg";
import emails from "../images/emails.jpg";
import stats from "../images/statistics.jpg";
import search from "../images/search.jpg";
import categories from "../images/categories.png";
import Title from "../components/common/Title";
export default class AboutView extends Component {
  render() {
    return (
      <div
        className="container p-2 animate__animated animate__zoomIn shadow "
        style={{ height: "80%" }}
      >
        <div className="row p-3 w-100 shadow" style={{}}>
          <Helmet>
            <title>{`${AppTitle} | About Us`}</title>
          </Helmet>
          <div>
            <Title>About Us</Title>
            <hr />
            Bills Monk is An Online App for managing your bills and debts
            efficiently. Bills Monk is also a Reminder tool for your Bills and
            Debts. Bills Monk's goal is to Ensure{" "}
            <strong className="font-weight-bold">You Remember to Pay</strong> on
            Time.
            <div>
              <h2 className="text-uppercase">How It Works</h2>
              <div>
                <ol>
                  <li>Register a Bills Monk Account</li>
                  <li>Login To your Bills Monk Account</li>
                  <li>
                    Add A Bill/Debt. You can also view existing Bills and Debts.
                  </li>
                  <li>
                    Bills Monk will then:
                    <ul>
                      <li>
                        Send you Occasional REMINDERS about your Bills and
                        Debts.{" "}
                      </li>
                      <li>Automatically Update the Payment dates.</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
            <h2 className="text-uppercase">how do we remind you? </h2>
            <div className="row" style={{ zIndex: 30 }}>
              <Card
                img={pushImg}
                imgAlt="push notifications"
                title="Push Notifications"
              >
                We send Push notifications about your debts/bills. For example
                an upcoming bill.
              </Card>
              <Card title="Email Updates" img={emails} imgAlt="Email Updates">
                Useful email updates regarding your debts/bills. We will update
                you on time about upcoming bills and debts.
              </Card>
              <Card
                title="Useful Statistics"
                img={stats}
                imgAlt="Useful Statistics"
              >
                Useful statistics and charts about your debts/bills
              </Card>
              <Card
                title="Categorize Bills"
                img={categories}
                imgAlt="Categorize Bills"
              >
                Organize your bills into different categories. This makes it
                easier for you to manage the bills.
              </Card>
              <Card
                title="Search &amp; Filter Feature"
                img={search}
                imgAlt="Search &amp; Filter Feature"
              >
                <ul>
                  <li>Search bills/debts</li>
                  <li>Filter bills/debts</li>
                </ul>
              </Card>
            </div>
            <hr />
            <p>
              <Link to="/debts" className="px-3">
                Add Debt
              </Link>
              <Link to="/bills/new" className="px-3">
                Add Bill
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const Card = ({ title, children, img, imgAlt }) => {
  return (
    <div className="col-md-6  p-2 toast" style={{ zIndex: 300 }}>
      <div class="card  shadow shadow-lg border">
        <img
          class="card-img-top"
          src={img}
          alt={imgAlt}
          style={{ width: "100%" }}
        />
        <div class="card-body">
          <h5 class="card-title" style={{ color: "green" }}>
            {title}
          </h5>
          <p class="card-text">{children}</p>
        </div>
      </div>
    </div>
  );
};
