import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BillItem from "../components/bills/BillItem";
import ApiUrl, { AppTitle } from "../components/common/Constants";
import handlePromiseError from "../components/common/handlePromiseError";
import {
  getAxiosConfig,
  showNotification,
} from "../components/common/HelperFunctions";
import Loader from "../components/common/Loader";
import NotFoundItem from "../components/common/NotFoundItem";

export default function BillView(props) {
  const [titleText, setTitleText] = useState("Bill");
  const [bill, setBill] = useState({});
  const { id } = useParams();

  const getBill = () => {
    let url = ApiUrl + "/bills/bills";

    url += `?bill_id=${id}`;

    trackPromise(
      handlePromiseError(
        Axios.get(url, getAxiosConfig()).then((resp) => {
          if (resp.data.success) {
            let bills = resp.data.message.bills;

            const data = bills.data[0];
            if (data) {
              setTitleText(data.bill_name);
              setBill(data);
            }
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  useEffect(getBill, []);

  return (
    <div>
      <Helmet>
        <title>{`${AppTitle} | Bills | ${titleText || "Bill Item"}`}</title>
      </Helmet>
      <div className="container">
        <Loader title="Wait a moment as we fetch the bill..." />
        <div className="row">
          {Object.keys(bill).length > 0 ? (
            <>
              <BillItem bill={bill} isOne={true} />
              <div className="col-12 p-2">
                <a href="/bills">Other Bills</a>
              </div>
            </>
          ) : (
            <NotFoundItem item="Bill" href="/bills" />
          )}
        </div>
      </div>
    </div>
  );
}
