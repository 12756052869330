import React, { Component } from "react";
import PropTypes from "prop-types";
import CategoryItem from "./CategoryItem";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";

export default class ListCategories extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div
            className="col-md-8 offset-md-2 p-3"
            style={{ backgroundColor: "#5daedd" }}
          >
            <div>
              Bill Categories make it easier to manage your bills. You can
              classify your bills into different categories that you would
              create.
              <br />
              <Link to="#">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <Loader title="Wait a moment as we fetch the categories..." />

          <div className="col-md-8 offset-md-2">
            <ul className="list-group animate__animated animate__zoomIn">
              {this.props.categories.map((category, id) => (
                <CategoryItem
                  {...this.props}
                  deleteCategory={this.props.deleteCategory}
                  initiateEdit={this.props.initiateEdit}
                  category={category}
                  key={id}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ListCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  initiateEdit: PropTypes.func.isRequired,
};
