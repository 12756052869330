import Axios from "axios";
import React from "react";
import ApiUrl from "../common/Constants";
import { Link } from "react-router-dom";
import CategoriesBillsParent from "../parents/CategoriesBillsParent";
import { getAxiosConfig, showNotification } from "../common/HelperFunctions";
import {
  InputDescriptionButton,
  InputDescriptionText,
} from "../common/InputDescription";
import handlePromiseError from "../common/handlePromiseError";
import SubmitButton from "../common/SubmitButton";
import { trackPromise } from "react-promise-tracker";

export default class AddNewBill extends CategoriesBillsParent {
  state = {
    category_id: "",
    bill_name: "",
    bill_amount: "",
    bill_description: "",
    paybill_number: "",
    bill_cycle: "",
    paydate: "",
    m_account_number: "",
    bank_name: "",
    bank_account_number: "",
    bank_branch: "",
    bill_type_id: "",
    billTypes: [],
    categories: [],
    billCycles: {},
    isEdit: false,
    bill_id: null,
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const bill_id = params.get("bill_id");
    if (bill_id) {
      this.getBills(null, bill_id);
    }
    this.getCategories();
    this.getBillTypes();
    this.getBillCycles();
  }

  getBillCycles = () => {
    const url = ApiUrl + "/bills/bill_cycles";
    handlePromiseError(
      Axios.get(url, getAxiosConfig()).then((resp) => {
        if (resp.data.success) {
          this.setState({
            billCycles: resp.data.message.bill_cycles,
          });
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };

  getBillTypeName = (id) => {
    if (id && this.state.billTypes.length > 0) {
      let billType = this.state.billTypes.find((billType) => {
        return billType.id === parseInt(id);
      });

      return billType.name;
    }
    return "";
  };

  getBillTypes = () => {
    const url = ApiUrl + "/admin/bill_types";

    handlePromiseError(
      Axios.get(url, getAxiosConfig()).then((resp) => {
        if (resp.data.success) {
          this.setState({
            billTypes: resp.data.message.bill_types,
          });
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };

  addBill = (e) => {
    e.preventDefault();

    const url = ApiUrl + "/bills/add_bill";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            bill_name: this.state.bill_name,
            category_id: this.state.category_id,
            bill_description: this.state.bill_description,
            bill_type_id: this.state.bill_type_id,
            bill_amount: this.state.bill_amount,
            bill_cycle: this.state.bill_cycle,
            paybill_number: this.state.paybill_number,
            paydate: this.state.paydate,
            m_account_number: this.state.m_account_number,
            bank_account_number: this.state.bank_account_number,
            bank_branch: this.state.bank_branch,
            bank_name: this.state.bank_name,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            showNotification("success", resp.data.message, "s");
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  editBill = (e) => {
    e.preventDefault();

    const url = ApiUrl + "/bills/edit_bill";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            id: this.state.bill_id,
            bill_name: this.state.bill_name,
            category_id: this.state.category_id,
            bill_description: this.state.bill_description,
            bill_type_id: this.state.bill_type_id,
            bill_amount: this.state.bill_amount,
            bill_cycle: this.state.bill_cycle,
            paybill_number: this.state.paybill_number,
            paydate: this.state.paydate,
            m_account_number: this.state.m_account_number,
            bank_account_number: this.state.bank_account_number,
            bank_branch: this.state.bank_branch,
            bank_name: this.state.bank_name,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            showNotification("success", resp.data.message, "s");
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  getOptions() {
    const { billCycles } = this.state;
    let options = [];

    for (let key in billCycles) {
      options.push(
        <option value={key} key={key}>
          {billCycles[key]}
        </option>
      );
    }

    return options;
  }

  viewBillsButton = () => {
    return (
      <span style={{ float: "right" }}>
        <Link className="btn btn-primary text-white" to="/bills">
          <i className="fas fa-list-alt"></i> View My Bills
        </Link>
      </span>
    );
  };

  render() {
    return (
      <div className="container animate__animated animate__zoomIn">
        <div className="row p-3">
          <div
            className="col-md-8 offset-md-2 shadow border p-4"
            style={{ backgroundColor: "#16504b", color: "white" }}
          >
            <h2>
              {this.state.isEdit ? "Edit" : "Add New"} Bill{" "}
              {this.viewBillsButton()}
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.isEdit) {
                  this.editBill(e);
                } else {
                  this.addBill(e);
                }
              }}
            >
              <div className="form-group">
                <label htmlFor="bill_name">
                  Name of the Bill{" "}
                  <InputDescriptionButton elementId="bill_name_desc" />
                </label>
                <InputDescriptionText elementId="bill_name_desc">
                  This is the name of the bill you intend to add.
                </InputDescriptionText>

                <input
                  type="text"
                  name="bill_name"
                  id="bill_name"
                  className="form-control"
                  placeholder="zuku internet"
                  required
                  title="Use a name which you can easily remember about the bill."
                  value={this.state.bill_name}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="category_id">
                  Category of the Bill{" "}
                  <InputDescriptionButton elementId="category_id_desc" />
                </label>
                <InputDescriptionText elementId="category_id_desc">
                  This is the category of the bill you intend to add. You can
                  put your bills in different categories. You can also{" "}
                  <Link to="/categories">Add A new category</Link> if it is not
                  here.
                </InputDescriptionText>

                <select
                  name="category_id"
                  id="category_id"
                  className="form-control"
                  required
                  value={this.state.category_id}
                  onChange={this.onChange}
                >
                  <option value={""} defaultValue>
                    Select Category
                  </option>
                  {this.state.categories.map((category, id) => (
                    <option
                      value={category.id}
                      key={id}
                      title={category.category_description}
                    >
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="bill_cycle">
                  How often to do you want to pay the Bill{" "}
                  <InputDescriptionButton elementId="category_id_desc" />
                </label>
                <InputDescriptionText elementId="bill_cycle_desc">
                  This is the cycle of the bill payment. For example, you want
                  to paid it once, monthly or even per year.
                </InputDescriptionText>

                <select
                  name="bill_cycle"
                  id="bill_cycle"
                  className="form-control"
                  required
                  value={this.state.bill_cycle}
                  onChange={this.onChange}
                >
                  <option value={""} defaultValue>
                    Select A Payment Cycle
                  </option>
                  {this.getOptions()}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="bill_type_id">
                  Payment Method
                  <InputDescriptionButton elementId="bill_type_desc" />{" "}
                </label>
                <InputDescriptionText elementId="bill_type_desc">
                  The kind of payment method you will use to pay the bill.
                </InputDescriptionText>
                <select
                  name="bill_type_id"
                  id="bill_type_id"
                  className="form-control"
                  onChange={this.onChange}
                  required
                  value={this.state.bill_type_id}
                >
                  <option value={""} defaultValue>
                    Select Payment Method
                  </option>
                  {this.state.billTypes.map((billType, id) => (
                    <option
                      value={billType.id}
                      title={billType.description}
                      key={id}
                    >
                      {billType.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="bill_amount">
                  Bill Amount
                  <InputDescriptionButton elementId="bill_amount_desc" />
                </label>
                <InputDescriptionText elementId="bill_amount_desc">
                  The amount to be paid for the bill.
                </InputDescriptionText>
                <input
                  type="text"
                  id="bill_amount"
                  name="bill_amount"
                  className="form-control"
                  placeholder="e.g 1000"
                  value={this.state.bill_amount}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="paydate">
                  Date of Bill Payment{" "}
                  <InputDescriptionButton elementId="paydate_desc" />
                </label>
                <InputDescriptionText elementId="paydate_desc">
                  The date the bill will first be paid.Note that we will adjust
                  the dates automatically for you depending on the cycle of
                  payment you chose for the bill.
                </InputDescriptionText>

                <input
                  type="date"
                  name="paydate"
                  id="paydate"
                  className="form-control"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="We will use this date to give you reminders"
                  value={this.state.paydate}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="bill_description">
                  Short description of the bill
                </label>
                <textarea
                  name="bill_description"
                  id="bill_description"
                  cols="30"
                  rows="3"
                  className="form-control"
                  placeholder="I use this for paying zuku internet monthly"
                  value={this.state.bill_description}
                  onChange={this.onChange}
                ></textarea>
              </div>
              {this.getBillTypeName(this.state.bill_type_id) === "M-Pesa" ? (
                <div className="animate__animated animate__slideInLeft">
                  <div className="form-group">
                    <label htmlFor="paybill_number">
                      Mpesa Pay Bill Number
                    </label>
                    <input
                      type="text"
                      name="paybill_number"
                      id="paybill_number"
                      className="form-control"
                      placeholder="e.g 220220"
                      value={this.state.paybill_number}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="m_account_number">
                      Account Number to Pay To
                    </label>
                    <input
                      type="text"
                      name="m_account_number"
                      id="m_account_number"
                      className="form-control"
                      value={this.state.m_account_number}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              ) : null}

              {this.getBillTypeName(this.state.bill_type_id) === "Bank" ? (
                <div className="animate__animated animate__slideInLeft">
                  <div className="form-group">
                    <label htmlFor="bank_name">Bank</label>
                    <input
                      type="text"
                      name="bank_name"
                      id="bank_name"
                      className="form-control"
                      value={this.state.bank_name}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bank_account_number">
                      Bank Account Number to Pay To
                    </label>
                    <input
                      type="text"
                      name="bank_account_number"
                      id="bank_account_number"
                      className="form-control"
                      value={this.state.bank_account_number}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bank_branch">The Bank Branch</label>
                    <input
                      type="text"
                      name="bank_branch"
                      id="bank_branch"
                      className="form-control"
                      value={this.state.bank_branch}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              ) : null}
              <SubmitButton
                normalDisplay={this.state.isEdit ? "Edit Bill" : "Add Bill"}
                loadDisplay={
                  this.state.isEdit ? "Editing Bill..." : "Adding Bill..."
                }
                normalFas={this.state.isEdit ? "fas fa-edit" : "fas fa-plus"}
                loadFas="fas fa-spinner fa-pulse"
              />
              <div className="my-3">{this.viewBillsButton()}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
