import debounce from "lodash.debounce";
import React from "react";
import { useState } from "react";

export default function Search({ callback, placeholder }) {
  const [q, setq] = useState("");
  const styles = {
    padding: "5px",
    fontSize: "16px",
  };

  const debouncedCallback = debounce(callback, 3000);
  return (
    <div className="row" style={{ boxSizing: "border-box", display: "table" }}>
      <input
        type="search"
        placeholder={placeholder || "Search..."}
        style={{
          padding: styles.padding,
          fontSize: styles.fontSize,
          border: "1px solid grey",
          float: "left",
          width: "80%",
          background: "#f1f1f1",
        }}
        value={q}
        onChange={(e) => {
          let value = e.target.value;
          setq(value);
          debouncedCallback(value);
        }}
      />
      <button
        type="button"
        style={{
          float: "left",
          width: "20%",
          padding: styles.padding,
          fontSize: styles.fontSize,
          background: "#2196F3",
          color: "white",
          border: "1px solid grey",
          borderLeft: "none" /* Prevent double borders */,
          cursor: "pointer",
        }}
        onClick={() => {
          callback(q);
          setq("");
        }}
      >
        <i className="fa fa-search"></i>
      </button>
    </div>
  );
}
