import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AddNewBill from "../components/bills/AddNewBill";
import { AppTitle } from "../components/common/Constants";

export default class CreateBillView extends Component {
  state = {
    title: "New Bill",
  };
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const bill_id = params.get("bill_id");
    if (bill_id) {
      this.setState({
        title: "Edit Bill",
      });
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>{`${AppTitle} | ${this.state.title}l`}</title>
        </Helmet>
        <AddNewBill {...this.props} />
      </div>
    );
  }
}
