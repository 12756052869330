import React from "react";

function Jumbotron({ children }) {
  return (
    <div
      className="jumbotron m-2 text-dark border border-info shadow"
      style={{
        backgroundColor: "#d4edda",
      }}
    >
      {children}
    </div>
  );
}

export default Jumbotron;
