import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CategoriesContainer from "../components/categories/CategoriesContainer";
import { AppTitle } from "../components/common/Constants";

export default class CategoriesView extends Component {
  render() {
    return (
      <div className="container">
        <Helmet>
          <title>{`${AppTitle} | Bill Categories`}</title>
        </Helmet>
        <CategoriesContainer {...this.props} />
      </div>
    );
  }
}
