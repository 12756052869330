import React, { Component } from "react";

export default class ParentComponent extends Component {
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  pagination = (callback) => {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link btn-outline-primary"
              disabled={this.state.paginate.current_page === 1}
              onClick={() => {
                this.updatePreviousPage(callback);
              }}
            >
              Previous
            </button>
          </li>

          <li className="page-item bg-white text-dark p-1">
            Page {this.state.paginate.current_page}
          </li>

          <li className="page-item">
            <button
              className="page-link btn-outline-primary"
              disabled={
                this.state.paginate.to - this.state.paginate.from + 1 <
                this.state.paginate.per_page
              }
              onClick={() => {
                this.updateNextPage(callback);
              }}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  updateNextPage = (callback) => {
    let diff = this.state.paginate.to - this.state.paginate.from + 1;
    console.log(diff);
    if (diff < this.state.paginate.per_page) {
      return;
    }

    this.setState(
      {
        paginate: {
          ...this.state.paginate,
          current_page: this.state.paginate.current_page + 1,
        },
      },
      callback
    );
  };

  updatePreviousPage = (callback) => {
    this.setState(
      {
        paginate: {
          ...this.state.paginate,
          current_page: this.state.paginate.current_page - 1,
        },
      },
      callback
    );
  };

  updatePagination = (
    current_page,
    per_page,
    to,
    from,
    callback = () => {
      console.log("updated pagination");
    }
  ) => {
    this.setState(
      {
        paginate: {
          ...this.state.paginate,
          current_page: current_page,
          per_page: per_page,
          to: to,
          from: from,
        },
      },
      callback
    );
  };
}
