import { UPDATE_USER } from "../actions/actionTypes";

const initialState = {};

const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
