import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Button extends Component {
  onClick = () => {
    if (this.props.callback) {
      this.props.callback();
    }
  };
  render() {
    return (
      <React.Fragment>
        <button
          type={this.props.callback ? "button" : "submit"}
          className="btn btn-primary btn-block"
          onClick={this.onClick}
        >
          <i className={this.props.fas}></i>
          {" " + this.props.display}
        </button>
      </React.Fragment>
    );
  }
}

Button.defaultProps = {
  display: "Submit",
};

Button.propTypes = {
  display: PropTypes.string,
  callback: PropTypes.func,
  fas: PropTypes.string,
};
