import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ListCountries extends Component {
  render() {
    return this.props.countries.map((country, id) => (
      <option value={country.id} key={id}>
        {country.name}
      </option>
    ));
  }
}

ListCountries.propTypes = {
  countries: PropTypes.array.isRequired,
};
