import Axios from "axios";
import React from "react";
import Button from "../common/Button";
import ApiUrl, { loginRoute } from "../common/Constants";
import handleErrorResponse from "../common/HandleErrors";
import ListCountries from "./ListCountries";
import ParentComponent from "../ParentComponent";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getAxiosConfig, showNotification } from "../common/HelperFunctions";
import { Link } from "react-router-dom";
import SubmitButton from "../common/SubmitButton";
import handlePromiseError from "../common/handlePromiseError";
import { trackPromise } from "react-promise-tracker";

export default class SignUp extends ParentComponent {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country_id: "",
    u_password: "",
    countries: [],
    c_password: "",
    p_match: "d-none",
    partOne: true,
    confirmDone: false,
    phoneCode: "",
  };

  componentDidMount() {
    this.getCountries();
  }

  getCountries = () => {
    const url = ApiUrl + "/admin/countries";

    handlePromiseError(
      Axios.get(url).then((resp) => {
        if (resp.data.success) {
          this.setState({
            countries: resp.data.message.countries,
          });
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };

  checkPasswordMatch = (e) => {
    if (this.state.c_password === this.state.u_password) {
      this.setState({
        p_match: "",
      });
      return true;
    } else {
      this.setState({
        p_match: "d-none",
      });
      return false;
    }
  };

  setPhoneCode = (countryId) => {
    let country = this.state.countries.find((country) => {
      return country.id === parseInt(countryId);
    });

    this.setState({
      phoneCode: country.phone_code,
    });
  };

  registerUser = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/users/register";

    trackPromise(
      handlePromiseError(
        Axios.post(url, {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          phone_number: this.state.phoneCode + this.state.phone_number,
          country_id: this.state.country_id,
          u_password: this.state.u_password,
        }).then((resp) => {
          this.continuePart(true);
          if (resp.data.success) {
            showNotification("success", resp.data.message, "s");
            this.setState({
              confirmDone: true,
            });
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        }),
        (err) => {
          this.continuePart(true);
          handleErrorResponse(err);
        }
      )
    );
  };

  checkEmailPhone = () => {
    if (
      this.state.email.length > 4 &&
      this.state.phone_number > 5 &&
      this.state.country_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  continuePart = (isBack = false) => {
    if (isBack) {
      this.setState({
        partOne: true,
      });
    } else {
      if (this.checkEmailPhone()) {
        this.setState({
          partOne: false,
        });
      } else {
        showNotification(
          "Missing Fields",
          "Please provide email,phone number and country",
          "i"
        );
      }
    }
  };

  resendConfirm = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/general/resend_email";

    handlePromiseError(
      Axios.post(
        url,
        {
          email: this.state.email,
        },
        getAxiosConfig()
      ).then((resp) => {
        if (resp.data.success) {
          showNotification("success", resp.data.message, "s");
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };

  confirmEmail = () => {
    return (
      <div className="mx-auto my-3 p-4" style={{ backgroundColor: "#16504b" }}>
        <div className="text-center m-1 py-3">
          <h2>Check Your Inbox</h2>
          <div>
            An email is on it's way to{" "}
            <span className="font-weight-bold">{this.state.email}</span> to ask
            your confirmation.
          </div>
        </div>
        <div>
          Didn't receive an email? check your spam folder or{" "}
          <Link to="#" onClick={this.resendConfirm}>
            click here to resend again
          </Link>
          . <br />
          Email already confirmed? check your spam folder or{" "}
          <Link to={loginRoute}>Continue to Login</Link>
        </div>
      </div>
    );
  };

  signupForm = () => {
    return (
      <div
        className="mx-auto my-3 p-3 border border-white"
        style={{ backgroundColor: "#16504b", color: "wheat" }}
      >
        <div className="text-center">
          <h2 style={{ color: "yellow" }}>Bills Monk</h2>
          <h3>Lets Get To Know You!</h3>
        </div>
        <form className="p-3" onSubmit={this.registerUser}>
          {this.state.partOne ? (
            <React.Fragment>
              <div className="form-group">
                <label htmlFor="country_id">Select your Country</label>
                <select
                  name="country_id"
                  id="country_id"
                  required
                  className="form-control"
                  onChange={(e) => {
                    this.onChange(e);
                    this.setPhoneCode(e.target.value);
                  }}
                  value={this.state.country_id}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  <ListCountries countries={this.state.countries} />
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="email">What is your Email address?</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  required
                  placeholder="Enter email"
                  value={this.state.email}
                  autoComplete="off"
                  onChange={this.onChange}
                />
                <small className='"text-muted'>
                  We will send a confirmation to this email
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="phone_number">Your Phone Number?</label>
                <div className="d-flex flex-row">
                  <div
                    className={
                      (this.state.phoneCode ? "" : "d-none") +
                      " bg-white rounded text-dark px-2 "
                    }
                  >
                    {this.state.phoneCode}
                  </div>

                  <input
                    type="tel"
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    placeholder="Enter phone number e.g +254712345678"
                    required
                    value={this.state.phone_number}
                    autoComplete="off"
                    onChange={this.onChange}
                    pattern="[+]{1}[0-9]{12}"
                  />
                </div>
              </div>
              <Button
                fas="fas fa-arrow-right"
                display="Continue"
                callback={this.continuePart}
              />
            </React.Fragment>
          ) : (
            <div className="animate__animated animate__zoomIn">
              <h3>Sorry, Some more information and we are done!</h3>
              <div className="form-group">
                <label htmlFor="first_name">What is your First Name?</label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                  placeholder="Enter first name"
                  required
                  value={this.state.first_name}
                  autoComplete="off"
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">What about your Last Name?</label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  name="last_name"
                  required
                  placeholder="Enter last name"
                  value={this.state.last_name}
                  autoComplete="off"
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="u_password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="u_password"
                  name="u_password"
                  required
                  placeholder="Enter password"
                  value={this.state.u_password}
                  autoComplete="off"
                  onChange={(e) => {
                    this.onChange(e);
                    this.checkPasswordMatch(e);
                  }}
                />
              </div>
              <div
                className={
                  this.state.p_match +
                  " alert alert-warning alert-dismissible fade show"
                }
                role="alert"
                id="match"
              >
                The passwords do not match!
              </div>
              <div className="form-group">
                <label htmlFor="c_password">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="c_password"
                  name="c_password"
                  required
                  placeholder="Confirm password"
                  value={this.state.c_password}
                  autoComplete="off"
                  onChange={(e) => {
                    this.onChange(e);
                    this.checkPasswordMatch(e);
                  }}
                />
              </div>
              <SubmitButton
                normalDisplay="Register"
                loadDisplay="Registering..."
                normalFas="fas fa-user-plus"
              />
            </div>
          )}
        </form>
        <div className=" h1 p-3">
          <hr className="bg-white" />
          Already A Member?
          <NavLink to={loginRoute}> Login here</NavLink>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        {this.state.confirmDone ? this.confirmEmail() : this.signupForm()}
      </React.Fragment>
    );
  }
}
