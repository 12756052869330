import React from "react";
import { retrieveLocal } from "../components/common/HelperFunctions";
import HomeView from "../views/HomeView";

const WithRouteHoc = (Component) => {
  const NewComponent = (props) => {
    let result = retrieveLocal("token");
    if (!result) {
      return <HomeView />;
    } else {
      return <Component {...props} />;
    }
  };

  return NewComponent;
};

export default WithRouteHoc;
