import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppTitle } from "../components/common/Constants";
import SignUp from "../components/Signup/SignUp";

export default class RegisterView extends Component {
  render() {
    return (
      <div className="container">
        <Helmet>
          <title>{`${AppTitle} | Sign Up`}</title>
        </Helmet>
        <SignUp {...this.props} />
      </div>
    );
  }
}
