import { format, formatDistanceToNow } from "date-fns";
import ApiUrl, { tataConfig } from "./Constants";
import { error, success, text, log, info } from "tata-js";
import Axios from "axios";
import swal from "sweetalert";

/**
 * Save an Item to Local Storage
 * @param {String} key
 * @param {*} value
 */
export const storeLocal = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Get an Item from Local storage
 * @param {String} key
 */
export const retrieveLocal = (key) => {
  let value = window.localStorage.getItem(key);
  let result = JSON.parse(value);
  if (key === "token") {
    if (String(result).length <= 1) {
      return false;
    }
  }

  return result;
};

/**
 * Remove/Delete an Item from Local Storage
 * @param {String} key
 */
export const removeLocal = (key) => {
  window.localStorage.removeItem(key);
};

/**
 * Get Axios configuration object
 */
export function getAxiosConfig() {
  return {
    headers: {
      Authorization: "Bearer " + getToken(),
      Accept: "application/json",
    },
  };
}

/**
 * get access token
 */
export function getToken() {
  const token = retrieveLocal("token");
  return token;
}

/**
 * Change The first character to Uppercase
 * @param {String} string
 */
export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Give Relative date to Now
 * @param {Date} date
 */
export function formatDate(date) {
  return formatDistanceToNow(Date.parse(date), {
    addSuffix: true,
  });
}

/**
 * show notifications to users
 * @param {String} title
 * @param {String} msg
 * @param {String} type
 */

export function showNotification(title, msg, type = "s") {
  switch (type) {
    case "e":
      error(title, msg, tataConfig);
      break;
    case "s":
      success(title, msg, tataConfig);
      break;
    case "t":
      text(title, msg, tataConfig);
      break;
    case "l":
      log(title, msg, tataConfig);
      break;
    case "i":
      info(title, msg, tataConfig);
      break;
    default:
      success(title, msg, tataConfig);
  }
}

export function sendTokenToServer(token) {
  const url = ApiUrl + "/general/save_token";

  Axios.post(
    url,
    {
      token: token,
    },
    getAxiosConfig()
  )
    .then((resp) => {
      console.log("save token result", resp.data);
    })
    .catch((err) => {
      console.log("save token error", err);
    });
}

export function getCurrentMonth() {
  return format(new Date(), "MMMM");
}

export function ConfirmModal(
  actionFunc,
  message,
  icon = "warning",
  dangerMode = true,
  title = null
) {
  swal({
    title: title || "Are you sure?",
    text: message,
    icon: icon,
    buttons: true,
    dangerMode: dangerMode,
  }).then((isAllowed) => {
    if (isAllowed) {
      actionFunc();
    }
  });
}

export default storeLocal;
