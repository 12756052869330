import React from "react";

export default function Title({ children, textAlign }) {
  return (
    <h1
      style={{
        color: "#17a2b8",
        fontFamily: "Gordon",
        textAlign: textAlign || "left",
        width: "100%",
      }}
    >
      {children}
    </h1>
  );
}
