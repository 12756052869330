/* eslint-disable no-undef */
import firebase from "firebase/app";
import "firebase/analytics";

import "firebase/messaging";
import { firebaseConfig } from "./Constants";
import storeLocal, { sendTokenToServer } from "./HelperFunctions";

export async function initFirebase() {
  // Initialize Firebase
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  firebase.analytics();

  // Retrieve Firebase Messaging object.
  const messaging = firebase.messaging();

  //store token
  let token = null;

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  try {
    let currentToken = await messaging.getToken({
      vapidKey:
        "BPv_BY5RZr6nK70fBE0-0U_wmA1saUZG6Kv3-s4NnP7yh3hPA02bO8uGf4y7IwPUVyRmki74gXwqIRY7wQnKOu8",
    });

    if (currentToken) {
      token = currentToken;
      storeLocal("pushToken", currentToken);
      sendTokenToServer(currentToken);
    } else {
      // Show permission request.
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // Show permission UI.
      updateUIForPushPermissionRequired();
    }

    return token;
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  // messaging
  //   .getToken({
  //     vapidKey:
  //       "BPv_BY5RZr6nK70fBE0-0U_wmA1saUZG6Kv3-s4NnP7yh3hPA02bO8uGf4y7IwPUVyRmki74gXwqIRY7wQnKOu8",
  //   })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       token = currentToken;
  //       storeLocal("pushToken", currentToken);
  //       sendTokenToServer(currentToken);
  //     } else {
  //       // Show permission request.
  //       console.log(
  //         "No registration token available. Request permission to generate one."
  //       );

  //       // Show permission UI.
  //       updateUIForPushPermissionRequired();
  //     }
  //   })
  //   .catch((err) => {
  //     console.log("An error occurred while retrieving token. ", err);
  //   });

  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  messaging.onMessage((payload) => {
    console.log("Message received. ", payload);
    // ...
  });

  return token;
}
