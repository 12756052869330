import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConfirmModal } from "./HelperFunctions";

export default class DeleteButton extends Component {
  deleteAction = () => {
    this.props.onClick();
  };
  render() {
    return (
      <React.Fragment>
        <span
          title={this.props.title || "Delete"}
          className="deleteBtn"
          onClick={() => {
            ConfirmModal(
              this.deleteAction,
              "Once deleted, you will not be able to recover!"
            );
          }}
        >
          <i className="fas fa-trash-alt"></i> Delete
        </span>
      </React.Fragment>
    );
  }
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};
