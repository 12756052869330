import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../common/HelperFunctions";
import DeleteButton from "../common/DeleteButton";
import { format } from "date-fns";
import "../../css/debts.scss";

export default class ListDebts extends Component {
  state = {
    debts: {},
  };

  componentDidMount() {
    this.setState({
      debts: this.props.debts,
    });
  }

  render() {
    return (
      <div className="row animate__animated animate__zoomIn">
        {this.props.debts.map((debt, id) => (
          <div
            className="col-12  border  m-2 shadow rounded p-1"
            id={id}
            key={debt.id}
          >
            <div
              className="card p-3"
              style={{
                zIndex: "20",
                backgroundColor: "#707070",
                color: "white",
              }}
            >
              <h2 className="my-0">
                {" "}
                <strong>
                  {debt.amount_text}{" "}
                  {debt.is_paid ? (
                    <span className="badge badge-info text-uppercase">
                      paid
                    </span>
                  ) : null}
                </strong>
              </h2>
              <small>
                <i className="fas fa-user-tie pr-1"></i>
                {debt.full_name}
              </small>
              <div id={debt.id}></div>
              <div>
                <div>
                  <div>
                    {debt.is_paid ? (
                      <div>
                        <span className="text-success">
                          <i className="fas fa-check-circle"></i> Debt is Paid{" "}
                        </span>
                        {debt.paid_at ? (
                          <div className="pl-3">
                            Paid on{" "}
                            <span className="text-info">
                              {new Date(debt.paid_at).toLocaleDateString()}(
                              {formatDate(debt.paid_at)})
                            </span>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <span className="text-warning">
                        <i className="far fa-times-circle"></i> Debt is Not Paid{" "}
                      </span>
                    )}
                  </div>
                </div>
                {!debt.is_owes_me ? (
                  <div className="badge">
                    <i className="fas fa-exclamation-triangle fa-2x mr-1 text-warning"></i>
                    You Owe
                  </div>
                ) : null}
                {debt.remind_at ? (
                  <div>
                    <hr />
                    <i className="fas fa-calendar-alt pr-2"></i> Remind on{" "}
                    <span style={{ color: "orange" }}>
                      {new Date(debt.remind_at).toLocaleDateString()} (
                      {formatDate(debt.remind_at)})
                    </span>
                  </div>
                ) : null}

                {debt.started_at ? (
                  <div>
                    Started on{" "}
                    <span style={{ color: "green" }}>
                      {" "}
                      {new Date(debt.started_at).toLocaleDateString()} (
                      {formatDate(debt.started_at)})
                    </span>
                  </div>
                ) : null}
                <hr className="pb-1 mb-0" />
                <div className="p-0 m-0" style={{ fontSize: "60%" }}>
                  Created on {format(new Date(debt.created_at), "PPPP")}
                </div>
                <hr className="pt-1 mt-1" />
                <div style={{ float: "right" }}>
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div
                      className="px-1 mx-1 border-right actions"
                      onClick={() => {
                        this.props.initiateEdit(debt);
                      }}
                    >
                      <i className="fas fa-edit"></i> Edit
                    </div>
                    <div
                      className="px-2 mx-1 border-right actions"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.togglePaid(debt.id, debt.is_paid);
                      }}
                    >
                      {debt.is_paid ? (
                        <span title="Mark this debt as not paid">
                          <i className="fas fa-times-circle"></i> Not Paid
                        </span>
                      ) : (
                        <span title="Mark this debt as paid">
                          <i className="fas fa-check-double"></i> Paid
                        </span>
                      )}
                    </div>
                    <div
                      className="px-1 mx-1 actions"
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteButton
                        onClick={() => {
                          this.props.deleteDebt(debt.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

ListDebts.propTypes = {
  debts: PropTypes.array.isRequired,
  deleteDebt: PropTypes.func.isRequired,
  togglePaid: PropTypes.func.isRequired,
  initiateEdit: PropTypes.func.isRequired,
};
