import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../css/navbar.scss";

class Navbar extends Component {
  render() {
    return (
      <div className="shadow py-0 container-fluid my-nav" id="navbar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-0 shadow">
          <NavLink
            className="navbar-brand"
            activeClassName="active is-active"
            to="/"
          >
            Bills M
            <span role="img" aria-label="monkey emoji">
              🐒
            </span>
            nk
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              {/* TODO: this below is a hack!!! - cetric 12/2/2021 */}
              <NewNavItem title="" link="" />
              <NewNavItem title="Bills" link="/bills" />
              <NewNavItem title="Debts" link="/debts" />
              <NewNavItem title="Categories" link="/categories" />
              <NewNavItem title="About" link="/about" />
              <NewNavItem title="Help" link="/help" />
            </ul>

            <li className="nav-item dropdown pr-4">
              <NavLink
                activeClassName="is-active"
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-user-circle fa-2x pb-3"></i>
              </NavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <NavLink
                  activeClassName="is-active"
                  className="dropdown-item"
                  to="/user/profile"
                >
                  <i className="fas fa-id-card"></i>
                  Profile
                </NavLink>

                <div className="dropdown-divider"></div>
                <NavLink
                  activeClassName="is-active"
                  className="dropdown-item"
                  to="#"
                >
                  <i className="fas fa-user-cog"></i> Settings
                </NavLink>
                <NavLink
                  activeClassName="is-active"
                  className="dropdown-item"
                  to="/logout"
                >
                  <i className="fas fa-sign-out-alt"></i> Logout
                </NavLink>
              </div>
            </li>
          </div>
        </nav>
      </div>
    );
  }
}

const NewNavItem = ({ title, link }) => {
  return (
    <li className="nav-item">
      <NavLink activeClassName="is-active" className="nav-link" to={link}>
        {title}
      </NavLink>
    </li>
  );
};

export default Navbar;
