import Axios from "axios";
import React from "react";
import ApiUrl, { paginate } from "../common/Constants";
import {
  ConfirmModal,
  getAxiosConfig,
  showNotification,
} from "../common/HelperFunctions";
import Loader from "../common/Loader";
import ParentComponent from "../ParentComponent";
import ListDebts from "./ListDebts";
import { Link } from "react-router-dom";
import {
  InputDescriptionButton,
  InputDescriptionText,
} from "../common/InputDescription";
import OverlayTrigger from "../common/OverlayTrigger";
import Title from "../common/Title";
import CloseButton from "../common/CloseButton";
import Search from "../common/Search";
import { trackPromise } from "react-promise-tracker";
import SubmitButton from "../common/SubmitButton";
import handlePromiseError from "../common/handlePromiseError";
import Jumbotron from "../common/Jumbotron";

export default class Debts extends ParentComponent {
  state = {
    debts: {},
    debts_array: [],
    showModal: false,
    full_name: "",
    amount: "",
    is_owes_me: "true",
    started_at: null,
    remind_at: null,
    isMoreDetails: false,
    id: null,
    isEdit: false,
    modalTitle: "Add New Debt",
    paginate: paginate,
    owes_me: 0,
    not_owes_me: 0,
    filters: {
      is_paid: 0,
      is_owes_me: "",
    },
    showStats: true,
  };

  componentDidMount() {
    this.getDebts();
  }

  editDebt = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/debts/edit_debt";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            id: this.state.id,
            full_name: this.state.full_name,
            amount: this.state.amount,
            is_owes_me: this.state.is_owes_me === "true" ? true : false,
            started_at: this.state.started_at,
            remind_at: this.state.remind_at,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            showNotification("success", resp.data.message);
            this.toggleEdit();
            this.resetValues();
            this.getDebts();
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  addDebt = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/debts/add_debt";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            full_name: this.state.full_name,
            amount: this.state.amount,
            is_owes_me: this.state.is_owes_me === "true" ? true : false,
            started_at: this.state.started_at,
            remind_at: this.state.remind_at,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            showNotification("success", resp.data.message);
            this.resetValues();
            this.getDebts();
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  initiateEdit = (debt) => {
    this.setState({
      id: debt.id,
      full_name: debt.full_name,
      amount: debt.amount,
      is_owes_me: debt.is_owes_me,
      started_at: debt.started_at
        ? new Date(debt.started_at).toISOString().split("T")[0]
        : null,
      remind_at: debt.remind_at
        ? new Date(debt.remind_at).toISOString().split("T")[0]
        : null,
      modalTitle: "Edit Debt",
    });

    this.toggleEdit();
    this.toggleModal();
  };

  getDebts = (q = null) => {
    let url = ApiUrl + "/debts/debts";

    let debtId = null;
    if (this.props.match.params.id) {
      debtId = this.props.match.params.id;
    }

    let params = {};

    if (debtId) {
      params.debt_id = debtId;
    } else {
      if (q) {
        params.q = q;
      } else {
        params.page = this.state.paginate.current_page;

        if (this.state.filters.is_paid !== "") {
          params.is_paid = this.state.filters.is_paid;
        }

        if (this.state.filters.is_owes_me !== "") {
          params.is_owes_me = this.state.filters.is_owes_me;
        }
      }
    }

    const config = { ...getAxiosConfig(), params: params };

    trackPromise(
      handlePromiseError(
        Axios.get(url, config).then((resp) => {
          if (resp.data.success) {
            let debts = resp.data.message.debts;
            this.setState({
              debts: debts,
              debts_array: debts.data,
              owes_me: resp.data.message.owes_me,
              not_owes_me: resp.data.message.not_owes_me,
            });

            this.updatePagination(
              debts.current_page,
              debts.per_page,
              debts.to,
              debts.from
            );
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  resetValues = () => {
    this.setState({
      id: null,
      full_name: "",
      amount: "",
      is_owes_me: "true",
      showModal: false,
      started_at: null,
      remind_at: null,
      modalTitle: "Add New Debt",
    });
  };

  deleteDebt = (id) => {
    ConfirmModal(() => {
      const url = ApiUrl + "/debts/delete_debt";

      handlePromiseError(
        Axios.post(
          url,
          {
            id: id,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            showNotification("success", resp.data.message, "s");
            this.getDebts();
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      );
    }, "Once deleted, you will not be able to recover!");
  };

  togglePaid = (id, is_paid) => {
    ConfirmModal(
      () => {
        const url = ApiUrl + "/debts/mark_paid";
        trackPromise(
          handlePromiseError(
            Axios.post(
              url,
              {
                id: id,
                is_paid: !is_paid,
                paid_at: null,
              },
              getAxiosConfig()
            ).then((resp) => {
              if (resp.data.success) {
                showNotification("success", resp.data.message, "s");
                this.getDebts();
              } else {
                showNotification(resp.data.error, resp.data.description, "e");
              }
            })
          )
        );
      },
      is_paid !== true
        ? "You are marking this debts as paid."
        : "You are marking this debt as not yet paid.",
      "info",
      false
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
    });
  };

  toggleMoreDetails = () => {
    this.setState({
      isMoreDetails: !this.state.isMoreDetails,
    });
  };

  defaultEmpty = () => {
    return (
      <Jumbotron>
        <h1 className="display-4">Hello</h1>
        <p className="lead h3 py-3">
          No debts were found. Please add some debts for you to view them here.
        </p>
        <p>
          Add both debts people owe you and those that you owe other people. We
          would sent you occasional reminders about this debts.
        </p>
        <Link
          className="btn btn-primary btn-lg"
          to="#"
          onClick={() => {
            this.toggleModal();
          }}
          role="button"
        >
          <i className="fas fa-plus-circle"></i>
          Add Debts
        </Link>
      </Jumbotron>
    );
  };

  addDebtModal = () => {
    return (
      <OverlayTrigger>
        <div
          className="col-md-8 p-5 offset-md-2 shadow shadow-lg animate__animated animate__zoomIn"
          style={{
            backgroundColor: "#16504b",
            top: "10%",
            overflow: "auto",
            height: "80%",
          }}
        >
          <div>
            <div
              className="text-danger h2 mb-3"
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "0",
                right: "0",
                marginRight: "0.9rem",
                marginTop: "0.5rem",
              }}
              onClick={() => {
                this.toggleModal();
                if (this.state.isEdit) {
                  this.toggleEdit();
                  this.resetValues();
                }
              }}
              title="click to close"
            >
              <CloseButton />
            </div>
          </div>
          <div>
            <h2>{this.state.modalTitle} </h2>
          </div>
          <form
            onSubmit={this.state.isEdit ? this.editDebt : this.addDebt}
            autoComplete="off"
          >
            <div className="form-group">
              <label htmlFor="full_name">
                Person Name
                <InputDescriptionButton elementId="full_name_desc" />
              </label>
              <InputDescriptionText elementId="full_name_desc">
                This is the name of the person who owes you or whom you owe.
              </InputDescriptionText>

              <input
                name="full_name"
                id="full_name"
                type="text"
                className="form-control"
                placeholder="Enter the name of the person"
                required
                value={this.state.full_name}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="amount">
                Amount Owe
                <InputDescriptionButton elementId="amount_desc" />
              </label>
              <InputDescriptionText elementId="amount_desc">
                This is the debt amount. The amount owed.
              </InputDescriptionText>

              <input
                type="number"
                name="amount"
                id="amount"
                className="form-control"
                required
                placeholder="Enter the debt amount"
                value={this.state.amount}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="is_owes_me">
                Who responsible for the debt?
                <InputDescriptionButton elementId="is_owes_me_desc" />
              </label>
              <InputDescriptionText elementId="is_owes_me_desc">
                Does this person owe you or you owe them the amount above?
              </InputDescriptionText>

              <select
                className="form-control"
                id="is_owes_me"
                name="is_owes_me"
                value={this.state.is_owes_me}
                onChange={this.onChange}
                required
              >
                <option value={true} defaultValue>
                  They owe me
                </option>
                <option value={false}> I owe them</option>
              </select>
            </div>
            <div
              className={
                (this.state.isMoreDetails ? "text-success" : " text-info") +
                " m-1"
              }
              style={{ cursor: "pointer" }}
              onClick={this.toggleMoreDetails}
            >
              <i
                className={
                  (this.state.isMoreDetails
                    ? "fas fa-caret-down"
                    : "fas fa-caret-right") + " m-2 pb-3"
                }
              ></i>{" "}
              Add More Details
            </div>
            {this.state.isMoreDetails ? (
              <div className="m-1 p-1">
                <div className="form-group">
                  <label htmlFor="started_at">
                    Date the debt started?
                    <InputDescriptionButton elementId="started_at_desc" />
                  </label>
                  <InputDescriptionText elementId="started_at_desc">
                    The date which this debt was incurred.
                  </InputDescriptionText>

                  <input
                    type="date"
                    name="started_at"
                    id="started_at"
                    className="form-control"
                    placeholder="Debt started on"
                    value={this.state.started_at || ""}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="remind_at">
                    When do you want to be reminded about this debt?{" "}
                    <InputDescriptionButton elementId="remind_at_desc" />
                  </label>
                  <InputDescriptionText elementId="remind_at_desc">
                    Will will send you an email,push notification and
                    sms(future) to remind you.
                  </InputDescriptionText>

                  <input
                    type="date"
                    name="remind_at"
                    id="remind_at"
                    className="form-control"
                    placeholder="Debt started on"
                    value={this.state.remind_at || ""}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            ) : null}

            <div className="py-3">
              <SubmitButton
                normalDisplay={this.state.isEdit ? "Edit Debt" : "Add Debt"}
                loadDisplay={
                  this.state.isEdit ? "Editing Debt..." : "Adding New Debt..."
                }
                loadFas="fas fa-spinner"
                normalFas={this.state.isEdit ? "fas fa-edit" : "fas fa-plus"}
              />
            </div>
          </form>
        </div>
      </OverlayTrigger>
    );
  };

  updateFilters = (filter, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [filter]: value,
        },
      },
      this.getDebts
    );
  };

  render() {
    return (
      <div className="container">
        <Title>Debts</Title>
        <div className="row justify-content-end  p-2">
          <div className="col">
            <Search callback={this.getDebts} placeholder="Search debts..." />
          </div>
          <div className="col">
            <div className="row">
              <div className="col-md p-1">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    this.updateFilters("is_paid", e.target.value);
                  }}
                  value={this.state.filters.is_paid}
                >
                  <option value={""}>All</option>
                  <option value={1}>Paid</option>
                  <option value={0}>Not Paid</option>
                </select>
              </div>
              <div className="col-md p-1">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    this.updateFilters("is_owes_me", e.target.value);
                  }}
                  value={this.state.filters.is_owes_me}
                >
                  <option value={""}>All</option>
                  <option value={1}>Owe Me</option>
                  <option value={0}>I Owe (Need to Pay)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end pr-1 m-0">
          <Link
            onClick={() => {
              this.setState({
                showStats: !this.state.showStats,
              });
            }}
          >
            {this.state.showStats ? "Hide Stats" : "Show Stats"}
          </Link>
        </div>
        <div
          className={
            "row my-2 w-100 justify-content-center" +
            (this.state.showStats ? "" : "  d-none")
          }
        >
          <div className="col-md-5 m-1 bg-white text-dark text-center shadow border border-dark">
            To Be Paid
            <InputDescriptionButton elementId="to_be_paid_desc" />
            <br />
            <InputDescriptionText elementId="to_be_paid_desc">
              This is the amount that you will be paid. The total amount of debt
              other people owe you.
            </InputDescriptionText>
            <span className="text-success">
              <h2>
                <strong>{this.state.owes_me}</strong>
              </h2>
            </span>
          </div>
          <div className="col-md-5 m-1 bg-white text-dark text-center shadow border border-dark">
            To Pay
            <InputDescriptionButton elementId="to_pay_desc" />
            <br />
            <InputDescriptionText elementId="to_pay_desc">
              This is the amount that you have to pay. The total amount you owe
              others in debt.
            </InputDescriptionText>
            <span className="text-danger">
              <h2>
                {" "}
                <strong>{this.state.not_owes_me}</strong>
              </h2>
            </span>
          </div>
        </div>
        <div className="row" id="debts">
          <div className="col-12">
            <button
              className="btn btn-primary btn-lg"
              style={{ float: "right" }}
              onClick={() => {
                this.toggleModal();
              }}
            >
              <i className="fas fa-plus"></i> Add Debt
            </button>
          </div>
          {this.state.showModal ? this.addDebtModal() : null}
          <div className="col-12">
            <Loader title="Wait just a moment. We are fetching your debts..." />
            {this.state.debts_array.length < 1 ? (
              this.defaultEmpty()
            ) : (
              <ListDebts
                deleteDebt={this.deleteDebt}
                togglePaid={this.togglePaid}
                debts={this.state.debts_array}
                initiateEdit={this.initiateEdit}
              />
            )}
          </div>
          <div className="col-12">{this.pagination(this.getDebts)}</div>
        </div>
      </div>
    );
  }
}
