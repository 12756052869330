import React, { Component } from "react";
import DeleteButton from "../common/DeleteButton";
import PropTypes from "prop-types";
import { addDays, isBefore, isFuture } from "date-fns";
import { formatDate } from "../common/HelperFunctions";
import { Link } from "react-router-dom";
import {
  InputDescriptionButton,
  InputDescriptionText,
} from "../common/InputDescription";

export default class BillItem extends Component {
  paymentDetails = (bill) => {
    if (bill.bill_type_name === "M-Pesa") {
      return (
        <div className="col-12">
          <div style={{ fontSize: "50%" }}>
            <div>
              PayBill : <span className="pl-1">{bill.paybill_number}</span>
            </div>
            <div>
              A/C NO: <span className="pl-2">{bill.m_account_number}</span>
            </div>
          </div>
        </div>
      );
    } else if (bill.bill_type_name === "Bank") {
      return (
        <div className="col-12">
          <div style={{ fontSize: "50%" }}>
            <div>
              Bank : <span className="pl-2">{bill.bank_name}</span>
            </div>
            <div>
              Branch : <span className="pl-2">{bill.bank_branch}</span>
            </div>
            <div>
              A/C No : <span className="pl-2">{bill.bank_account_number}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const bill = this.props.bill;
    return (
      <div
        className={
          (this.props.isOne ? "col-md-6 offset-md-3 " : "col-md-6") +
          " bill_item shadow shadow-lg"
        }
      >
        <div className="card my-3">
          <div className="card-body" style={{ zIndex: 20 }}>
            <div className="card-title h3">
              {bill.bill_name}{" "}
              <ShowUrgent date={bill.next_pay_date} id={bill.id} />
            </div>
            <InputDescriptionText elementId={`due${bill.id}`}>
              <div className="alert alert-warning" role="alert">
                This Bill Needs to be Paid Urgently!
              </div>
            </InputDescriptionText>
            <div className="card-text">
              <hr />
              <div>
                <div className="row">
                  <div className="col-12 w-100">
                    <div
                      className="p-2 shadow"
                      style={{
                        backgroundColor: "yellow",
                        fontSize: "25px",
                      }}
                    >
                      <i className="far fa-money-bill-alt text-dark pr-1 m-0"></i>
                      <strong> {bill.bill_amount_text}</strong>
                      <br />
                      <small className="mt-0 pt-0" style={{ fontSize: "10px" }}>
                        <i
                          className="fas fa-comment-alt pr-2"
                          style={{ color: "green" }}
                        ></i>
                        {bill.bill_description
                          ? bill.bill_description
                          : "No Description"}
                      </small>
                    </div>
                  </div>
                </div>

                <div
                  className="row border badge badge-pill badge-info p-1 shadow"
                  style={{ float: "right" }}
                >
                  {bill.is_one_time ? (
                    <span title="This bill is a one time bill">One Time</span>
                  ) : null}
                </div>
                <hr />
                <div className="row m-1 shadow p-1">
                  <div className="p-1 col-2">
                    <i className="fas fa-clock"></i>
                  </div>
                  <div className="p-1 col-9">
                    <strong style={{ textDecoration: "underline" }}>
                      Next Pay Date:
                    </strong>{" "}
                    <br />
                    {new Date(bill.next_pay_date).toDateString()}
                    <br />
                    <div
                      className={
                        isUrgent(bill.next_pay_date)
                          ? "border border-danger p-1"
                          : ""
                      }
                    >
                      <strong>
                        {dateFuture(bill.next_pay_date)
                          ? "To Pay"
                          : "Was to be paid"}
                        <span style={{ color: "green", fontSize: "25px" }}>
                          {" " + formatDate(bill.next_pay_date)}
                        </span>
                      </strong>
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  className="row mx-auto w-100 my-2"
                  style={{
                    backgroundColor: "darkgoldenrod",
                    border: "1px dashed black",
                  }}
                >
                  <div className="col-6 border">
                    <div>
                      <small className="text-uppercase">Category</small>
                    </div>
                    <div style={{ color: "lavender" }}>
                      <i className="far fa-object-group pr-1"></i>
                      {bill.category_name}
                    </div>
                  </div>
                  <div className="col-6 border">
                    <div>
                      <small className="text-uppercase">Paid</small>
                    </div>
                    <div style={{ color: "lavender" }}>
                      <i className="fas fa-redo pr-1"></i>
                      <span className="text-capitalize">
                        {bill.bill_cycle_text}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border">
                    <div>
                      <small className="text-uppercase">Payment</small>
                    </div>
                    <div style={{ color: "lavender" }}>
                      <i className="far fa-gem pr-1"></i>
                      {bill.bill_type_name}
                    </div>
                    <div className="row text-white text-left w-100">
                      {this.paymentDetails(bill)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div style={{ fontSize: "70%" }}>
              <small className="mb-0">
                Created
                <span style={{ color: "gray" }}>
                  {" " + new Date(bill.created_at).toDateString()}{" "}
                </span>
              </small>
              <br />
              {Date.parse(bill.created_at) < Date.parse(bill.updated_at) ? (
                <small className="mt-0">
                  updated
                  <span style={{ color: "gray" }}>
                    {" " + new Date(bill.updated_at).toDateString()}{" "}
                  </span>
                </small>
              ) : null}
            </div>
            <div className="p-1">
              <Link
                className="btn btn-secondary "
                to={`/bills/new?bill_id=${bill.id}`}
              >
                Edit
              </Link>
              {this.props.deleteBill ? (
                <DeleteButton
                  onClick={() => {
                    this.props.deleteBill(bill.id);
                  }}
                  title="delete bill"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BillItem.propTypes = {
  deleteBill: PropTypes.func,
};

const isUrgent = (date) => {
  return dateFuture(date) && isBefore(Date.parse(date), addDays(new Date(), 7));
};

const dateFuture = (date) => {
  return isFuture(Date.parse(date));
};

const ShowUrgent = ({ date, id }) => {
  return (
    <>
      {isUrgent(date) ? (
        <span
          className="badge badge-danger float-right shadow"
          title="This Bill Needs to be Paid Urgently"
        >
          Due
          <InputDescriptionButton elementId={`due${id}`} />
        </span>
      ) : null}{" "}
    </>
  );
};
