import { removeLocal, showNotification } from "./HelperFunctions.js";

const handleErrorResponse = (err, isLogin = false) => {
  let errorResponse;

  if (err) {
    if (err.response && err.response.data) {
      // I expect the API to handle error responses in valid format
      errorResponse = err.response.data;
      // JSON stringify if you need the json and use it later
    } else if (err.request) {
      // TO Handle the default error response for Network failure or 404 etc.,
      errorResponse = err.request.message || err.request.statusText;
    } else {
      errorResponse = err.message;
    }
  }

  showNotification(
    errorResponse.error || "You are Offline",
    errorResponse.description ||
      "Please check that you are connected to internet.",
    "e"
  );

  if (err && err.response && err.response.status === 401) {
    console.log("removing stuff local");
    removeLocal("token");
    removeLocal("billUser");
    if (!isLogin) {
      window.location.reload();
    }
  }
};

export default handleErrorResponse;
