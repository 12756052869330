import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

export default function SubmitButton({
  loadDisplay,
  loadClass = "btn btn-primary btn-block",
  normalDisplay,
  normalClass = "btn btn-primary btn-block",
  loadFas,
  normalFas,
}) {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      <button
        type="submit"
        className={promiseInProgress ? loadClass : normalClass}
      >
        <i
          className={
            "pr-2 " +
            (promiseInProgress
              ? loadFas || "fas fa-spinner fa-pulse"
              : normalFas)
          }
        ></i>
        {promiseInProgress ? loadDisplay : normalDisplay}
      </button>
    </>
  );
}
