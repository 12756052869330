import Axios from "axios";
import React from "react";
import ApiUrl from "../common/Constants";
import ListCategories from "./ListCategories";
import CategoriesBillsParent from "../parents/CategoriesBillsParent";
import { getAxiosConfig, showNotification } from "../common/HelperFunctions";
import OverlayTrigger from "../common/OverlayTrigger";
import CloseButton from "../common/CloseButton";
import handlePromiseError from "../common/handlePromiseError";
import SubmitButton from "../common/SubmitButton";
import { trackPromise } from "react-promise-tracker";

export default class CreateCategory extends CategoriesBillsParent {
  state = {
    category_id: "",
    category_name: "",
    category_description: "",
    categories: [],
    display: "d-none",
    modalTitle: "",
    modalFas: "",
    isEdit: false,
    fas: "",
  };

  componentDidMount() {
    this.getCategories();
  }

  deleteCategory = (id) => {
    const url = ApiUrl + "/categories/delete_category";

    handlePromiseError(
      Axios.post(
        url,
        {
          id: id,
        },
        getAxiosConfig()
      ).then((resp) => {
        if (resp.data.success) {
          this.getCategories();
          showNotification("success", resp.data.message, "s");
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };

  resetState = () => {
    this.setState({
      category_id: "",
      category_name: "",
      category_description: "",
      isEdit: false,
    });
  };

  editCategory = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/categories/edit_category";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            id: this.state.category_id,
            category_name: this.state.category_name,
            category_description: this.state.category_description,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            this.getCategories();
            this.resetState();
            showNotification("success", resp.data.message, "s");
            this.hideShowModal("animate__backOutDown d-none");
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  initiateAdd = () => {
    this.setState(
      {
        modalTitle: "Add a New Bill Category",
        modalFas: "fas fa-plus",
      },
      () => {
        this.hideShowModal("animate__zoomIn");
      }
    );
  };

  createCategory = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/categories/add_category";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            category_name: this.state.category_name,
            category_description: this.state.category_description,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            this.getCategories();
            this.resetState();
            showNotification("success", resp.data.message, "s");
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  hideShowModal = (display) => {
    this.setState({ display });
  };

  initiateEdit = (category) => {
    this.setState(
      {
        category_id: category.id,
        category_name: category.category_name,
        category_description: category.category_description,
        modalTitle: "Edit Bill Category",
        modalFas: "fas fa-edit",
        isEdit: true,
      },
      () => {
        this.hideShowModal("animate__zoomIn");
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="row my-2">
          <div className="col-md-12 text-right">
            <button
              title="Add A new category for your bills"
              className="btn btn-primary"
              onClick={this.initiateAdd}
            >
              <i className="fas fa-plus"></i> Add Category
            </button>
          </div>
        </div>
        <div className={"row " + this.state.display}>
          <OverlayTrigger>
            <div
              className={
                "col-md-6 offset-md-3 card p-3 my-2 mx-auto text-white shadow animate__animated animate__zoomIn border"
              }
              style={{
                top: "20%",
                backgroundColor: "#16504b",
              }}
            >
              <span
                title="close modal"
                style={{
                  float: "right",
                  width: "99%",
                  textAlign: "right",
                  color: "red",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={() => {
                  this.hideShowModal("animate__backOutDown d-none");
                }}
              >
                <CloseButton />
              </span>
              <div className="card-body">
                <h2 className="card-title">{this.state.modalTitle}</h2>
                <div className="card-text">
                  <form
                    onSubmit={(e) => {
                      if (this.state.isEdit) {
                        this.editCategory(e);
                      } else {
                        this.createCategory(e);
                      }
                    }}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label htmlFor="category_name">Category Name</label>
                      <input
                        type="text"
                        name="category_name"
                        id="category_name"
                        className="form-control"
                        placeholder="Enter a name for the category"
                        value={this.state.category_name || ""}
                        onChange={this.onChange}
                        required
                        maxLength="20"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="category_description">
                        Category Description
                      </label>
                      <input
                        type="text"
                        name="category_description"
                        id="category_description"
                        className="form-control"
                        placeholder="A brief description of the category"
                        value={this.state.category_description || ""}
                        onChange={this.onChange}
                      />
                    </div>
                    <SubmitButton
                      normalDisplay={
                        this.state.isEdit ? "Edit Category" : "Add Category"
                      }
                      loadDisplay={
                        this.state.isEdit
                          ? "Editing Category..."
                          : " Adding New Category..."
                      }
                      normalFas={
                        this.state.isEdit ? "fas fa-edit" : "fas fa-plus"
                      }
                      loadFas="fas fas-spinner fa-pulse"
                    />
                  </form>
                </div>
              </div>
            </div>
          </OverlayTrigger>
        </div>

        <ListCategories
          deleteCategory={this.deleteCategory}
          categories={this.state.categories}
          initiateEdit={this.initiateEdit}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
