import Axios from "axios";
import React from "react";
import ApiUrl from "../../common/Constants";
import storeLocal, { showNotification } from "../../common/HelperFunctions";
import ParentComponent from "../../ParentComponent";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import PushPermissionModal from "./PushPermissionModal";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import SubmitButton from "../../common/SubmitButton";
import { trackPromise } from "react-promise-tracker";
import handlePromiseError from "../../common/handlePromiseError";

class Login extends ParentComponent {
  state = {
    email: "",
    u_password: "",
    fas: "fas fa-sign-in-alt",
    isShowModal: false,
  };

  passwordReset = () => {
    console.log("password reset");
    swal({
      text:
        "Enter the email address you registered with. We will sent password reset email there. Please check the email for further instructions.",
      content: "input",
      button: {
        text: "Submit",
        closeModal: false,
      },
    })
      .then((name) => {
        if (!name) throw new Error("Please provide email");

        let url = ApiUrl + "/general/reset_password";

        return Axios.post(url, {
          email: name,
        });
      })
      .then((resp) => {
        if (resp.data.success) {
          return swal("Success!", resp.data.message);
        } else {
          swal(resp.data.error, resp.data.description, "error");
        }
      })
      .catch((err) => {
        if (err) {
          swal("Oops!", err.message, "error");
          console.log(err);
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  toggleShowModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal,
    });
  };

  signInUser = (e) => {
    e.preventDefault();
    const url = ApiUrl + "/users/login";
    const data = {
      email: this.state.email,
      u_password: this.state.u_password,
    };

    trackPromise(
      handlePromiseError(
        Axios.post(url, data).then((resp) => {
          if (resp.data.success) {
            storeLocal("token", resp.data.message.jwt);
            storeLocal("billUser", resp.data.message.user);
            showNotification("Success", "You have logged in", "s");
            this.toggleShowModal();
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  render() {
    return (
      <div
        className="mx-auto my-3 p-3 border border-white shadow"
        style={{ backgroundColor: "#16504b", color: "wheat" }}
      >
        <h2 className="text-center" style={{ color: "yellow" }}>
          Bills M
          <span role="img" aria-label="Monkey Emogi">
            🐒
          </span>{" "}
          nk
        </h2>

        <h3 className="text-center">Sign In</h3>
        {this.state.isShowModal ? (
          <PushPermissionModal
            {...this.props}
            toggleShowModal={this.toggleShowModal}
          />
        ) : null}
        <h6 className="text-center">
          <small>Track you Bills and Debts. Efficiently. Effectively</small>{" "}
          <br />
          Enter your Email and Password to sign in to your{" "}
          <span style={{ color: "yellow" }}>Bills Monk Account</span>
        </h6>
        <form onSubmit={this.signInUser} className="p-3">
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              value={this.state.email}
              autoComplete="off"
              required
              onChange={this.onChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="u_password">Password</label>
            <input
              type="password"
              className="form-control"
              id="u_password"
              name="u_password"
              placeholder="Password"
              value={this.state.u_password}
              autoComplete="off"
              required
              onChange={this.onChange}
            />
          </div>
          <div>
            <SubmitButton
              normalDisplay="Sign In"
              loadDisplay="Signing In..."
              normalFas="fas fa-sign-in-alt"
              loadFas="fas fa-spinner fa-pulse"
            />

            <div>
              <small>
                <Link to="#" onClick={this.passwordReset}>
                  Forgot Password
                </Link>
              </small>
            </div>
          </div>
        </form>
        <div className="p-3">
          <hr className="bg-white" />
          <h2>
            New User?
            <NavLink to="/register"> Create Account</NavLink>
          </h2>
          <p className="p-1">
            Not sure what Bills Monk is all about?{" "}
            <a href="/about">Learn more about Bills Monk</a> instead{" "}
          </p>
        </div>
      </div>
    );
  }
}

export default Login;
