import Axios from "axios";
import { isValid, parseISO } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet";
import { trackPromise } from "react-promise-tracker";
import CloseButton from "../components/common/CloseButton";
import ApiUrl, { AppTitle } from "../components/common/Constants";
import handlePromiseError from "../components/common/handlePromiseError";
import storeLocal, {
  getAxiosConfig,
  jsUcfirst,
  retrieveLocal,
  showNotification,
} from "../components/common/HelperFunctions";
import OverlayTrigger from "../components/common/OverlayTrigger";
import SubmitButton from "../components/common/SubmitButton";
import ParentComponent from "../components/ParentComponent";

export default class ProfileView extends ParentComponent {
  state = {
    type: "",
    name: "",
    title: "",
    show: "d-none",
    id: "",
    first_name: "",
    last_name: "",
    country_id: "",
    email: "",
    phone_number: "",
    gender: "",
    date_birth: "",
    created_at: "",
  };
  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    let user = retrieveLocal("billUser");
    this.setState({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      gender: user.gender,
      country_id: user.country_id,
      created_at: user.created_at,
      date_birth: user.date_birth,
    });
  };

  editProfile = (e) => {
    e.preventDefault();
    this.toggleModal("d-none");

    const url = ApiUrl + "/users/edit_user";

    trackPromise(
      handlePromiseError(
        Axios.post(
          url,
          {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone_number: this.state.phone_number,
            country_id: this.state.country_id,
            gender: this.state.gender,
            date_birth: this.state.date_birth,
          },
          getAxiosConfig()
        ).then((resp) => {
          if (resp.data.success) {
            storeLocal("billUser", resp.data.message.user);
            showNotification("success", resp.data.message.msg, "s");
            this.getUser();
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  setEditFields = (type, name, id, title, show = "d-none") => {
    this.setState({
      type,
      name,
      id,
      title,
      show,
      options: ["male", "female", "other"],
    });
  };

  toggleModal = (value) => {
    this.setState({
      show: value,
    });
  };

  editProfileModal = () => {
    return (
      <div className={this.state.show + " row"}>
        <OverlayTrigger>
          <div
            className="col-md-7 offset-md-3 p-5 border animate__animated animate__zoomIn"
            style={{
              backgroundColor: "white",
              top: "30%",
              color: "black",
              opacity: "1",
            }}
          >
            <div
              className="text-danger p-1"
              style={{ float: "right", cursor: "pointer", display: "block" }}
              onClick={() => {
                this.toggleModal("d-none");
              }}
            >
              <CloseButton />
            </div>
            <form onSubmit={this.editProfile}>
              <div className="form-group">
                <label htmlFor={this.state.id}>{this.state.title}</label>
                {this.state.type !== "select" ? (
                  <input
                    type={this.state.type}
                    className="form-control mb-3"
                    name={this.state.name}
                    id={this.state.id}
                    onChange={this.onChange}
                    value={this.state[this.state.name] || ""}
                  />
                ) : (
                  <select
                    className="form-control mb-3"
                    name={this.state.name}
                    id={this.state.id}
                    onChange={this.onChange}
                    value={this.state[this.state.name] || ""}
                  >
                    <option value="" defaultValue>
                      Select gender
                    </option>
                    {this.state.options.map((option, id) => {
                      return (
                        <option value={option} key={id}>
                          {jsUcfirst(option)}
                        </option>
                      );
                    })}
                  </select>
                )}
                <SubmitButton
                  normalDisplay="Edit Profile"
                  loadDisplay="Editing Profile..."
                  normalFas="fas fa-edit"
                />
              </div>
            </form>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  render() {
    return (
      <div className="container p-3">
        <Helmet>
          <title>{`${AppTitle}  | Profile`} </title>
        </Helmet>
        {this.editProfileModal()}
        <h1 style={{ color: "#17a2b8", fontFamily: "Gordon" }}>
          <span className="pr-2 text-capitalize">{this.state.first_name}</span>
          <span className="text-capitalize">{this.state.last_name}</span>
        </h1>
        <ul className="list-group text-dark">
          <li className="list-group-item">
            <span className="text-primary">
              <i className="fas fa-mobile-alt"></i> phone Number
            </span>{" "}
            : {this.state.phone_number}
            <EditBtn
              onClick={() => {
                this.setEditFields(
                  "tel",
                  "phone_number",
                  "phone_number",
                  "Edit Phone Number",
                  ""
                );
              }}
            />
          </li>
          <li className="list-group-item">
            <span className="text-primary">
              <i className="fas fa-envelope"></i> Email
            </span>{" "}
            : {this.state.email}
            <EditBtn
              onClick={() => {
                this.setEditFields("email", "email", "email", "Edit Email", "");
              }}
            />
          </li>
          <li className="list-group-item">
            <span className="text-primary">
              <i className="fas fa-venus-mars"></i> Gender{" "}
            </span>
            :{" "}
            {this.state.gender
              ? jsUcfirst(this.state.gender)
              : "Not updated yet"}
            <EditBtn
              onClick={() => {
                this.setEditFields(
                  "select",
                  "gender",
                  "gender",
                  "Edit Gender",
                  ""
                );
              }}
            />
          </li>
          <li className="list-group-item">
            <span className="text-primary">
              <i className="fas fa-calendar-alt"></i> Date of Birth
            </span>{" "}
            :
            {isValid(parseISO(this.state.date_birth))
              ? new Date(this.state.date_birth).toLocaleDateString()
              : "Not yet updated"}
            <EditBtn
              onClick={() => {
                this.setEditFields(
                  "date",
                  "date_birth",
                  "date_birth",
                  "Edit Date of Birth",
                  ""
                );
              }}
            />
          </li>
          <li className="list-group-item mt-3 text-center">
            Joined : {new Date(this.state.created_at).toDateString()}
          </li>
        </ul>
      </div>
    );
  }
}

function EditBtn(props) {
  return (
    <i
      style={{ float: "right", cursor: "pointer" }}
      className="fas fa-edit p-3"
      title="click to Edit"
      onClick={() => {
        props.onClick();
      }}
    ></i>
  );
}
