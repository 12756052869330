import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import loader from "../../images/round.gif";

export default function Loader(props) {
  const { promiseInProgress } = usePromiseTracker({ delay: 1000 });
  return (
    <>
      {promiseInProgress === true ? (
        <div className="container text-center  p-3">
          <div>
            <img
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "95%",
              }}
              src={loader}
              alt="Loading data..."
            />
          </div>
          <div className="h2">
            {props.title
              ? props.title
              : "Wait a moment as we fetch the data..."}
          </div>
        </div>
      ) : null}
    </>
  );
}
