import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppTitle } from "../components/common/Constants";
import Debts from "../components/debts/Debts";

export default class DebtsView extends Component {
  render() {
    return (
      <div className="">
        <Helmet>
          <title>{`${AppTitle} | Debts`}</title>
        </Helmet>
        <Debts {...this.props} />
      </div>
    );
  }
}
