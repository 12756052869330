import BillsMonk from "./bills-monk";
import React, { Component } from "react";

export default class App extends Component {
  render() {
    return (
      <div>
        <BillsMonk />
      </div>
    );
  }
}
