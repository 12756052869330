import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import "animate.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { removeLocal } from "./components/common/HelperFunctions";
import DebtsView from "./views/DebtsView";
import BillsView from "./views/BillsView";
import ProfileView from "./views/ProfileView";
import CategoriesView from "./views/CategoriesView";
import RegisterView from "./views/RegisterView";
import CreateBillView from "./views/CreateBillView";
import AboutView from "./views/AboutView";
import PrivacyPolicy from "./views/PrivacyPolicy";
import HelpView from "./views/HelpView";
import { Component } from "react";
import WithRouteHoc from "./hoc/WithRouteHoc";
import { loggedInHome, loginRoute } from "./components/common/Constants";
import Dashboard from "./components/Home/Dashboard";
import BillView from "./views/BillView";

const LoggedInRoute = WithRouteHoc(Route);

class BillsMonk extends Component {
  render() {
    return (
      <div className="App lead">
        <Header />
        <BrowserRouter>
          <Navbar />
          <div style={{ minHeight: "70vh" }}>
            <Switch>
              <LoggedInRoute
                path="/"
                exact
                render={(props) => {
                  return <Dashboard {...props} />;
                }}
              />

              <Route path="/about" component={AboutView} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route
                path="/register"
                render={(props) => <RegisterView {...props} />}
              />

              <LoggedInRoute
                path="/categories"
                exact
                render={(props) => {
                  return <CategoriesView {...props} />;
                }}
              />
              <LoggedInRoute
                path="/bills"
                render={(props) => {
                  return <BillsView {...props} />;
                }}
                exact
              />
              <LoggedInRoute
                path="/bills/new"
                exact
                render={(props) => {
                  return <CreateBillView {...props} />;
                }}
              />

              <LoggedInRoute
                path="/bills/:id"
                render={(props) => {
                  return <BillView {...props} />;
                }}
                exact
              />
              <LoggedInRoute
                path="/help"
                exact
                render={(props) => {
                  return <HelpView {...props} />;
                }}
              />

              <LoggedInRoute
                path="/debts"
                exact
                render={(props) => {
                  return <DebtsView {...props} />;
                }}
              />
              <LoggedInRoute
                path="/debts/:id"
                exact
                render={(props) => {
                  return <DebtsView {...props} />;
                }}
              />

              <LoggedInRoute
                path="/user/profile"
                exact
                render={(props) => {
                  return <ProfileView />;
                }}
              />
              <LoggedInRoute
                path="/logout"
                exact
                render={(props) => <Logout {...props} />}
              />
              <Route
                path="*"
                render={(props) => <Redirect to={loggedInHome} />}
              />
            </Switch>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

function Logout(props) {
  removeLocal("token");
  removeLocal("billUser");

  return <Redirect to={loginRoute} />;
}

export default BillsMonk;
