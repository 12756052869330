import React from "react";

export const InputDescriptionButton = ({ elementId, extraClass }) => {
  let id = elementId + "_btn";
  let title1 = "click to expand";
  let title2 = "click to close text below";
  return (
    <span
      id={id}
      className="text-primary px-1"
      title={title1}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        let span = document.getElementById(id);

        if (span.title === title1) {
          span.title = title2;
        } else if (span.title === title2) {
          span.title = title1;
        }

        toggleInputDescription(e, elementId);
      }}
    >
      <i className={"far fa-question-circle " + extraClass}></i>
    </span>
  );
};

export const InputDescriptionText = ({ elementId, children }) => {
  return (
    <div id={elementId} style={{ display: "none" }}>
      <small>{children}</small>
    </div>
  );
};

const isNoneDisplay = (elementId) => {
  let display = document.getElementById(elementId).style.display;
  return display === "none" || display === "";
};

const toggleInputDescription = (e, elementId) => {
  e.preventDefault();

  if (isNoneDisplay(elementId)) {
    document.getElementById(elementId).style.display = "block";
  } else {
    document.getElementById(elementId).style.display = "none";
  }
};
