import React from "react";
import Jumbotron from "./Jumbotron";

export default function NotFoundItem({ item, href }) {
  return (
    <div className="col-12">
      <Jumbotron>
        <div className="text-center">
          <div>{item} Not Found</div>
          <div>
            <a className="p-1" href={href}>
              Go Back To {item}s
            </a>
            |
            <a className="p-1" href="/Help">
              Help
            </a>
            |
            <a className="p-1" href="/about">
              About Us
            </a>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
}
