import Axios from "axios";
import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import swal from "sweetalert";
import CloseButton from "../common/CloseButton";
import ApiUrl from "../common/Constants";
import handlePromiseError from "../common/handlePromiseError";
import { getAxiosConfig, showNotification } from "../common/HelperFunctions";
import OverlayTrigger from "../common/OverlayTrigger";
import SubmitButton from "../common/SubmitButton";

export default function FeatureRequestModal({ toggleShowFeatureModal }) {
  const [compliment, setCompliment] = useState("");
  const [feature, setFeature] = useState("");
  const handleSubmitFeedback = (e) => {
    e.preventDefault();

    const url = ApiUrl + "/general/feature_request";

    if (!feature && !compliment) {
      swal("Empty Fields", "Please fill at least one field", {
        icon: "info",
      });
      return;
    }

    trackPromise(
      handlePromiseError(
        Axios.post(url, { compliment, feature }, getAxiosConfig()).then(
          (resp) => {
            console.log(resp.data);
            if (resp.data.success) {
              showNotification("success", resp.data.message, "s");
            } else {
              showNotification(resp.data.error, resp.data.description);
            }
          }
        )
      )
    );
  };

  return (
    <OverlayTrigger>
      <div className="container">
        <div className="row">
          <div
            className={
              "col-md-6 offset-md-3 card p-3 my-2 mx-auto text-white shadow animate__animated animate__zoomIn border"
            }
            style={{
              top: "20%",
              backgroundColor: "#16504b",
            }}
          >
            <div
              className="text-danger p-1 text-right"
              style={{ float: "right", cursor: "pointer", display: "block" }}
              onClick={() => {
                toggleShowFeatureModal();
              }}
            >
              <CloseButton />
            </div>
            <div>
              <div>
                <h2>Your Feedback</h2>
              </div>
              <form onSubmit={handleSubmitFeedback}>
                <div className="form-group">
                  <label htmlFor="compliment">
                    What do you like About this App?
                  </label>
                  <textarea
                    name="compliment"
                    id="compliment"
                    cols="30"
                    rows="5"
                    className="form-control"
                    placeholder="something you like here..."
                    value={compliment}
                    onChange={(e) => {
                      setCompliment(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="feature">
                    What would you wish we add to this App?
                  </label>
                  <textarea
                    name="feature"
                    id="feature"
                    cols="30"
                    rows="5"
                    className="form-control"
                    placeholder="something we should add for you here..."
                    value={feature}
                    onChange={(e) => {
                      setFeature(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div>
                  <SubmitButton
                    normalDisplay="Submit Feedback"
                    loadDisplay="Submitting Feedback..."
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </OverlayTrigger>
  );
}
