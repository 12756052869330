import React, { Component } from "react";
import PropTypes from "prop-types";
import DeleteButton from "../common/DeleteButton";
import MoreButton from "../common/MoreButton";
import { Link } from "react-router-dom";
import { formatDate } from "../common/HelperFunctions";

export default class CategoryItem extends Component {
  dropDown = (category) => {
    return (
      <div className="dropdown-menu  m-4">
        <Link
          className="dropdown-item"
          to="#"
          onClick={() => {
            this.props.initiateEdit(category);
          }}
        >
          <i className="fas fa-edit"></i> Edit
        </Link>
        <Link className="dropdown-item" to="#">
          <i className="fas fa-archive"></i> Archive
        </Link>
        <div className="dropdown-divider"></div>
        <Link
          className="dropdown-item"
          to={{
            pathname: `/bills`,
            search: `?category_id=${category.id}`,
          }}
        >
          <i className="fas fa-eye"></i> Bills
        </Link>
      </div>
    );
  };
  render() {
    const category = this.props.category;
    return (
      <li className="list-group-item text-black my-1 shadow" id="category_list">
        <div className="card shadow-lg" style={{ backgroundColor: "#D3D3D3" }}>
          <div className="card-body">
            <div className="card-title">
              <div className="row">
                <div className="col-8">{category.category_name}</div>
                <div className="col-4">
                  <DeleteButton
                    onClick={() => {
                      this.props.deleteCategory(category.id);
                    }}
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
            <div className="card-text">
              {category.category_description}
              <hr />
              <div title={category.created_at}>
                Created -{" "}
                <span style={{ color: "green" }}>
                  {formatDate(category.created_at)}
                </span>
              </div>
              {Date.parse(category.created_at) <
              Date.parse(category.updated_at) ? (
                <div title={category.updated_at}>
                  Updated -{" "}
                  <span style={{ color: "green" }}>
                    {formatDate(category.updated_at)}
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              <ul
                style={{
                  float: "right",
                  listStyleType: "none",
                  zIndex: "1000",
                  opacity: "1.0",
                }}
              >
                <li className="dropdown ">
                  <Link
                    className="nav-link m-2"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <MoreButton />
                  </Link>
                  {this.dropDown(category)}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

CategoryItem.propTypes = {
  category: PropTypes.object.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  initiateEdit: PropTypes.func.isRequired,
};
