import React, { Component } from "react";

export default class OverlayTrigger extends Component {
  render() {
    return (
      <div
        style={{
          position: "fixed" /* Sit on top of the page content */,
          width: "100%" /* Full width (cover the whole page) */,
          height: "100%" /* Full height (cover the whole page) */,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor:
            "rgba(0,0,0,0.5)" /* Black background with opacity */,
          zIndex: 5000 /* Specify a stack order in case you're using a different order for other elements */,
          color: "white",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
