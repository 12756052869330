import React, { Component } from "react";
import { AppTitle } from "../../common/Constants";
import storeLocal from "../../common/HelperFunctions";
import OverlayTrigger from "../../common/OverlayTrigger";
import { initFirebase } from "../../common/pushNotification";
import permImg from "../../../images/permission.jpg";
import CloseButton from "../../common/CloseButton";

export default class PushPermissionModal extends Component {
  state = {
    isAllowed: false,
  };

  componentDidMount() {
    this.handlePermission();
  }

  handlePermission = () => {
    return navigator.permissions
      .query({ name: "notifications" })
      .then(this.permissionQuery)
      .catch((err) => console.log(err));
  };

  permissionQuery = (result) => {
    console.debug({ result });
    if (result.state === "granted") {
      this.setState({
        isAllowed: true,
      });

      storeLocal("pushAllowed", 1);
    } else {
      storeLocal("pushAllowed", 0);
    }
  };

  redirect = () => {
    window.location.reload();
  };

  askPermission = () => {
    this.props.toggleShowModal();
    initFirebase().then((result) => {
      this.redirect();
    });
  };

  bodyContent = () => {
    return (
      <div className="container bg-primary border p-3 lead ">
        <div className="row ">
          <div className="col-12 text-right">
            <span
              style={{ cursor: "pointer", color: "red" }}
              onClick={this.redirect}
            >
              <CloseButton />
            </span>
          </div>
          <div className="col-12 text-center">
            <h2> {AppTitle}</h2>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <img
              src={permImg}
              alt="Asking Permission"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-12">
            <div>
              We would like to Send you
              <span className="text-warning text-uppercase">
                {" "}
                Push Notifications
              </span>{" "}
              about your Bills and Debts. This includes:
              <ul>
                <li>
                  {" "}
                  <i className="fas fa-bell pr-1"></i> Reminders
                </li>
                <li>
                  {" "}
                  <i className="fas fa-chart-bar pr-1"></i>Useful information
                  and statistics
                </li>
                <li>
                  <i className="fas fa-shield-alt pr-1"></i>Security information
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-6 p-3">
                <button
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={this.redirect}
                >
                  NOT NOW
                </button>
              </div>
              <div className="col-6 text-right p-3">
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={this.askPermission}
                >
                  GO AHEAD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <OverlayTrigger>
        <div
          className="m-3  mx-auto  p-3 animate__animated animate__zoomIn shadow shadow-lg"
          style={{
            maxHeight: "80vh",
            maxWidth: "80vh",
            top: 0,
            bottom: 0,
            fontFamily: "Arial,Verdana",
            color: "wheat",
            overflow: "auto",
            boxShadow: "2px 2px red;",
          }}
        >
          {" "}
          {this.state.isAllowed ? this.redirect() : this.bodyContent()}
        </div>
      </OverlayTrigger>
    );
  }
}
