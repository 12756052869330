import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppTitle } from "../components/common/Constants";
import Title from "../components/common/Title";
import FeatureRequestModal from "../components/Help/FeatureRequestModal";

export default class HelpView extends Component {
  state = {
    showFeatureModal: false,
  };

  toggleShowFeatureModal = () => {
    this.setState({
      showFeatureModal: !this.state.showFeatureModal,
    });
  };
  render() {
    return (
      <>
        {this.state.showFeatureModal ? (
          <FeatureRequestModal
            toggleShowFeatureModal={this.toggleShowFeatureModal}
          />
        ) : null}
        <div className="container lead animate__animated animate__zoomIn">
          <Helmet>
            <title>{`${AppTitle} | Help`}</title>
          </Helmet>
          <Title>Help</Title>
          <div className="row p-2">
            <div>
              What can we do to make this App useful to you?
              <div>
                <button
                  className="btn btn-info"
                  onClick={this.toggleShowFeatureModal}
                >
                  Give Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
