// export const ApiUrl =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_API_URL_LOCAL
//     : process.env.REACT_APP_API_URL;

export const ApiUrl = process.env.REACT_APP_API_URL;
export const AppTitle = process.env.APP_TITLE || "Bills Monk";

export const tataConfig = {
  closeBtn: true,
  position: "tr",
  duration: 5000,
  onClick: null,
  onClose: null,
};

// login route
export const loginRoute = "/";
// fav Home Page
export const loggedInHome = "/";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBO8dZWjEXtya4QLANjY5NHAUomFexfXhA",
  authDomain: "bills-monk.firebaseapp.com",
  databaseURL: "https://bills-monk.firebaseio.com",
  projectId: "bills-monk",
  storageBucket: "bills-monk.appspot.com",
  messagingSenderId: "818642257074",
  appId: "1:818642257074:web:babe36225617a9e07475ab",
  measurementId: "G-ZSZRR5RY55",
};

export const paginate = {
  current_page: 1,
  per_page: 0,
  to: 0,
  from: 0,
};

export default ApiUrl;
