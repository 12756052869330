import React, { PureComponent } from "react";
import "../css/header.scss";
import { AppTitle } from "./common/Constants";

export default class Header extends PureComponent {
  componentDidMount() {}
  render() {
    return (
      <header
        className="container"
        style={{ color: "yellow", fontFamily: "Gordon" }}
        id="header"
      >
        <div
          className="border m-1 bg-dark p-1 shadow header"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <h1 className="mb-0">{AppTitle}</h1>
          <small className="mt-0">Remember to Pay</small>
        </div>
      </header>
    );
  }
}
