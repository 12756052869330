import Axios from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import BillItem from "../bills/BillItem";
import CloseButton from "../common/CloseButton";
import ApiUrl, { AppTitle } from "../common/Constants";
import handlePromiseError from "../common/handlePromiseError";
import {
  getAxiosConfig,
  getCurrentMonth,
  retrieveLocal,
  showNotification,
} from "../common/HelperFunctions";
import Loader from "../common/Loader";
import OverlayTrigger from "../common/OverlayTrigger";
import { initFirebase } from "../common/pushNotification";
import Title from "../common/Title";
import {
  InputDescriptionButton,
  InputDescriptionText,
} from "../common/InputDescription";
import { connect } from "react-redux";

class Dashboard extends Component {
  state = {
    bills: {},
    debts: {},
    showBills: false,
  };

  toggleShowBills = () => {
    this.setState({
      showBills: !this.state.showBills,
    });
  };

  componentDidMount() {
    this.getStats();
    let pushAllowed = retrieveLocal("pushAllowed");

    if (pushAllowed === 1) {
      initFirebase();
    }
  }

  getStats = () => {
    let url = ApiUrl + "/general/dashboard";

    trackPromise(
      handlePromiseError(
        Axios.get(url, getAxiosConfig()).then((resp) => {
          console.log(resp);

          if (resp.data.success) {
            this.setState({
              bills: resp.data.message.bills,
              debts: resp.data.message.debts,
            });
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };
  render() {
    return (
      <>
        <Helmet>
          <title>{`${AppTitle} | Home`}</title>
        </Helmet>
        {this.state.showBills ? (
          <OverlayTrigger>
            <div
              className="row p-2 m-3    animate__animated animate__zoomIn justify-content-center"
              style={{
                overflow: "auto",
                height: "98%",
                backgroundColor: "#092f40",
                top: "20%",
              }}
            >
              <div className="col-12">
                <div className="row ">
                  <span
                    title="close modal"
                    className="pr-2"
                    style={{
                      float: "right",
                      width: "99%",
                      textAlign: "right",
                      color: "red",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                    onClick={() => {
                      this.toggleShowBills();
                    }}
                  >
                    <CloseButton />
                  </span>
                  <Title textAlign="center">{getCurrentMonth()} bills</Title>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {this.state.bills.unpaid_bills.map((bill) => (
                    <BillItem bill={bill} key={bill.id} isOne={true} />
                  ))}
                </div>
              </div>
              <div className="row">
                <Link className="btn btn-primary ml-3 mb-5" to="/bills">
                  View All Bills
                </Link>
              </div>
            </div>
          </OverlayTrigger>
        ) : null}

        <div className="container animate__animated animate__zoomIn">
          <Loader />
          <div className="row">
            <h3 className="p-1">
              Welcome, {this.props.user.first_name || "again"}
            </h3>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Bills Stats</h5>
                  <div className="card-text">
                    <div className="border jumbotron">
                      <h2>
                        {getCurrentMonth()} Bills{" "}
                        <InputDescriptionButton elementId="month_desc" /> <br />
                      </h2>
                      <div className="mb-2">
                        <InputDescriptionText elementId="month_desc">
                          This shows you this months tatal count of unpaid bills
                          and their total amount.
                        </InputDescriptionText>
                        <span className="text-danger">
                          {this.state.bills.unpaid_count + " bills"}
                          <span className="badge badge-danger m-2 h1">
                            {this.state.bills.unpaid_bills_amount}
                          </span>
                        </span>
                      </div>
                      <div>
                        {this.state.bills.unpaid_count > 0 ? (
                          <button
                            onClick={this.toggleShowBills}
                            className="btn btn-primary"
                          >
                            View {getCurrentMonth()} Bills
                          </button>
                        ) : this.state.bills.bills_count > 0 ? (
                          <Link className="btn btn-primary" to="/bills">
                            View Bills
                          </Link>
                        ) : (
                          <Link className="btn btn-primary" to="/bills/new">
                            Add Bill
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="jumbotron">
                      <h2>
                        Total
                        <InputDescriptionButton elementId="total_desc" />
                      </h2>
                      <div>
                        <InputDescriptionText elementId="total_desc">
                          This shows total count of bills you have and their sum
                          amount.
                        </InputDescriptionText>
                        <span className="text-info">
                          {this.state.bills.bills_count + " Bills"}
                          <span className="badge badge-info ml-2 h1">
                            {this.state.bills.bills_amount}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Debts Stats</h5>
                  <div className="card-text">
                    <div className="jumbotron">
                      <h2>
                        To Pay <InputDescriptionButton elementId="pay_desc" />{" "}
                      </h2>
                      <InputDescriptionText elementId="pay_desc">
                        This shows you the total number of people you owe(you
                        have to pay them) and the sum amount for payment.
                      </InputDescriptionText>
                      <div>
                        You have to pay:
                        <br />
                        <span className="text-danger">
                          {this.state.debts.not_owes_me_count + " people"}
                          <span className="badge badge-danger  ml-2 h1">
                            {this.state.debts.not_owes_me_amount}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="jumbotron">
                      <h2>
                        To Be Paid{" "}
                        <InputDescriptionButton elementId="paid_desc" />
                      </h2>
                      <InputDescriptionText elementId="paid_desc">
                        Shows you the number of people who owe you(they should
                        pay you) and the total sum of amount.
                      </InputDescriptionText>
                      <div>
                        You have to be paid by: <br />
                        <span className="text-info">
                          {this.state.debts.owes_me_count + " people "}
                          <span className="badge badge-success ml-2 h1">
                            {this.state.debts.owes_me_amount}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link to="/debts" className="btn btn-primary">
                    View Debts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Dashboard);
