import Axios from "axios";
import ApiUrl from "../common/Constants";
import ParentComponent from "../ParentComponent";
import { getAxiosConfig, showNotification } from "../common/HelperFunctions";
import { trackPromise } from "react-promise-tracker";
import handlePromiseError from "../common/handlePromiseError";

export default class CategoriesBillsParent extends ParentComponent {
  getCategories = () => {
    const url = ApiUrl + "/categories/categories";

    trackPromise(
      handlePromiseError(
        Axios.get(url, getAxiosConfig()).then((resp) => {
          if (resp.data.success) {
            this.setState({
              categories: resp.data.message.categories,
            });
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  getBills = (q = null, bill_id = null) => {
    const params = new URLSearchParams(this.props.location.search);
    const category_id = params.get("category_id");
    let url = ApiUrl + "/bills/bills";

    if (bill_id) {
      url += `?bill_id=${bill_id}`;
    } else if (q) {
      url += `?q=${q}`;
    } else {
      url += `?page=${this.state.paginate.current_page}`;
      if (this.state.category_id !== "false") {
        url += `&category_id=${this.state.category_id}`;
      } else if (category_id) {
        url += `&category_id=${category_id}`;
      }
    }

    trackPromise(
      handlePromiseError(
        Axios.get(url, getAxiosConfig()).then((resp) => {
          if (resp.data.success) {
            let bills = resp.data.message.bills;
            if (bill_id) {
              let bill = bills.data[0];
              this.setState({
                category_id: bill.category_id,
                bill_name: bill.bill_name,
                bill_amount: bill.bill_amount,
                bill_description: bill.bill_description,
                paybill_number: bill.paybill_number,
                bill_cycle: bill.bill_cycle,
                paydate: bill.next_pay_date
                  ? new Date(bill.next_pay_date).toISOString().split("T")[0]
                  : null,
                m_account_number: bill.m_account_number,
                bank_name: bill.bank_name,
                bank_account_number: bill.bank_account_number,
                bank_branch: bill.bank_branch,
                bill_type_id: bill.bill_type_id,
                isEdit: true,
                bill_id: bill.id,
              });
            } else {
              this.setState({
                bills: bills.data,
                statistics: resp.data.message.statistics,
              });

              this.updatePagination(
                bills.current_page,
                bills.per_page,
                bills.to,
                bills.from
              );
            }
          } else {
            showNotification(resp.data.error, resp.data.description, "e");
          }
        })
      )
    );
  };

  deleteBill = (id) => {
    const url = ApiUrl + "/bills/delete_bill";

    handlePromiseError(
      Axios.post(
        url,
        {
          id: id,
        },
        getAxiosConfig()
      ).then((resp) => {
        if (resp.data.success) {
          this.getBills();
          showNotification("success", resp.data.message, "s");
        } else {
          showNotification(resp.data.error, resp.data.description, "e");
        }
      })
    );
  };
}
