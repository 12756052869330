import React from "react";
import { paginate } from "../common/Constants";

import BillItem from "./BillItem";
import { Link } from "react-router-dom";
import CategoriesBillsParent from "../parents/CategoriesBillsParent";
import Loader from "../common/Loader";
import Title from "../common/Title";
import Search from "../common/Search";
import { getCurrentMonth } from "../common/HelperFunctions";
import Jumbotron from "../common/Jumbotron";

export default class BillsContainer extends CategoriesBillsParent {
  state = {
    bills: [],
    categories: [],
    category_id: "false",
    paginate: paginate,
    statistics: {
      bills_count: 0,
      total_amount: 0,
    },
  };

  componentDidMount() {
    this.getCategories();
    this.getBills();
  }

  render() {
    return (
      <div className="container  text-dark">
        <Title>Bills</Title>
        <div className="row m-2 py-2 w-100">
          <div className="col-md-8">
            <div className="form-group  row">
              <div className="col">
                <select
                  name="category_id"
                  id="category_id"
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    this.setState(
                      {
                        [e.target.name]: e.target.value,
                      },
                      this.getBills
                    );
                  }}
                  value={this.category_id}
                >
                  <option value={false} defaultValue>
                    All Categories
                  </option>
                  {this.state.categories.map((category, id) => {
                    return (
                      <option value={category.id} key={id}>
                        {category.category_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col">
                <Search
                  callback={this.getBills}
                  placeholder="Search bills...."
                />
              </div>
            </div>
          </div>
          <div className="text-right col-md-4" style={{ float: "right" }}>
            <Link className="btn btn-primary btn-lg" to="/bills/new">
              <i className="fas fa-plus"></i> Add Bill
            </Link>
          </div>
        </div>
        <hr className="bg-white" />
        <div className="row bg-white text-dark shadow p-3 border border-dark animate_animated animate__fadeInDown">
          <div className="col-12 text-center">
            <h2>{getCurrentMonth()} Summary</h2>
            <p>
              <span style={{ color: "red" }}>
                <strong>{this.state.statistics.bills_count}</strong>
              </span>{" "}
              {this.state.statistics.bills_count === 1 ? "Bill" : "Bills"}
              <br />
              <span style={{ color: "red" }}>
                <strong> {this.state.statistics.total_amount}</strong>
              </span>
              Total Amount
            </p>
          </div>
        </div>
        <div className="row animate__animated animate__zoomIn w-100">
          {this.state.bills.length > 0 ? (
            this.state.bills.map((bill) => (
              <BillItem
                bill={bill}
                key={bill.id}
                deleteBill={this.deleteBill}
              />
            ))
          ) : (
            <Jumbotron>
              <h1 className="display-5">Hello</h1>
              <p className="lead h3 py-3">
                No bills were found. Please add some bills for you to view them
                here.
              </p>
              <p>
                You may also <Link to="/categories">add categories</Link> for
                your bills. This will make it for you to organize your bills
                well. For example, you can have a category such as{" "}
                <span className="text-info">'home'</span> where you will put all
                home bills.
              </p>
              <p>
                Please note that Bills Monk will:
                <ul>
                  <li>
                    Send you Reminders about your bills. This will be sent to
                    the email your created account with and push notification to
                    your device(if you gave the permission){" "}
                  </li>
                  <li>
                    Automatically recalculate the next pay date for your bill.
                    For example, if you pay a monthly rent of $10 on 3rd of each
                    month, Bills Monk will always Automatically update the next
                    pay date which will be 3rd of the month after.
                  </li>
                </ul>
              </p>
              <Link
                className="btn btn-primary btn-lg"
                to="/bills/new"
                role="button"
              >
                <i className="fas fa-plus-circle"></i>
                Add Bills
              </Link>
            </Jumbotron>
          )}

          <Loader title="Wait a moment as we fetch the bills..." />
        </div>
        <div className="row" id="pagination">
          <div className="col-12">{this.pagination(this.getBills)}</div>
        </div>
      </div>
    );
  }
}
