import React, { Component } from "react";
import { Helmet } from "react-helmet";
import BillsContainer from "../components/bills/BillsContainer";
import { AppTitle } from "../components/common/Constants";
import {
  retrieveLocal,
  sendTokenToServer,
} from "../components/common/HelperFunctions";

export default class BillsView extends Component {
  componentDidMount() {
    let token = retrieveLocal("pushToken");

    if (token) {
      sendTokenToServer(token);
    }

    console.log("save push token in bills");
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{`${AppTitle} | Bills`}</title>
        </Helmet>
        <BillsContainer {...this.props} />
      </div>
    );
  }
}
