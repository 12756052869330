import React, { Component } from "react";
import Title from "../common/Title";
import CreateCategory from "./CreateCategory";

export default class CategoriesContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <Title>Bill Categories</Title>
        <CreateCategory {...this.props} />
      </React.Fragment>
    );
  }
}
